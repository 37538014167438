
import React, { useEffect, useMemo, useState } from 'react';
import { Grid, TextField } from '@mui/material';
import SSOLogin from './SSOLogin';
import classes from "../../assets/styles/Login.module.css";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton } from "@mui/material";
import { CircularProgress } from '@mui/material';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { loadingActions, setLoading } from '../../app/slices/loadingSlice';
import { setIsOtpEnabled, setIsOtpVerified, setIsSSO, setIsSubscribed, setUserType, userActions } from '../../app/slices/userDataSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import * as authServices from "../../services/auth-services.proxy";
import { setIsCMS, setIsLoggedIn } from '../../app/slices/userDataSlice';
import Message from '../../sharedComponents/ui/Message';
import { useIntl } from 'react-intl';

const clientRegistration = authServices.clientRegistration;

const ClientRegistration = ({ loginFormState, setLoginFormState }: { loginFormState: string, setLoginFormState: any }) => {

  const intl = useIntl();

  // const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [name, setName] = useState('');
  const [nameError, setNameError]= useState(false);
  const [nameErrorMsg, setNameErrorMsg]= useState('');

  const [confirmPass, setConfirmPass] = useState('');
  const [confirmPassError, setConfirmPassError] = useState(false);
  const [confirmPassErrorMsg, setConfirmPassErrorMsg] = useState('');

  const [emailError, setEmailError] = useState<boolean>(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState<string>('');

  const [passError, setPassError] = useState<boolean>(false);
  const [passErrorMsg, setPassErrorMsg] = useState<string>('');

  const [BeErrorMsg, setBeErrorMsg] = useState<string>('');

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPass, setShowConfirmPass]= useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleClickShowConfirmPass = () => setShowConfirmPass(!showConfirmPass);
  const handleMouseDownConfirmPass = () => setShowConfirmPass(!showConfirmPass);

  const loading = useAppSelector((state) => state.loading.value);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();


  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();

  const tenantId = query.get("tenant");
  const email = query.get("email");

  const clientObject = {
    email: email,
    password: password,
    name: name,
    tenantId: tenantId
  }
  const clientRegistrationCall = () => {
    dispatch(setLoading(true))
    clientRegistration(clientObject).then((x) => {
      if (x.ErrorMessage) {
        // setLoginFormState('SSO-fail');
        // localStorage.setItem('loginFormState', 'SSO-fail')
        // // setTimeout(() => {
        // //   setLoginFormState('default');
        // //   localStorage.setItem('loginFormState', 'default')
        // // }, 4000)
      }
      else {

        // //setLoginFormState('verify-user');
        // //localStorage.setItem('loginFormState','verify-user')
        // // dispatch(userActions.updateToken(x.accessToken));

        // localStorage.setItem('ssoType', 'google');

        localStorage.setItem("isLoggedIn", 'true');
        dispatch(setIsLoggedIn(true));

        localStorage.setItem("isSSO", 'false');
        dispatch(setIsSSO(false));

        localStorage.setItem("isCMS", 'false');
        dispatch(setIsCMS(false));

        localStorage.setItem("isOtpEnabled", 'false');
        dispatch(setIsOtpEnabled(false));

        localStorage.setItem("isOtpVerified", 'false');
        dispatch(setIsOtpVerified(false));

        localStorage.setItem("userType", '2');
        dispatch(setUserType(2));

         navigate('/2fa')

      }
      dispatch(setLoading(false))
    })
  }

  return (
    <Grid item container direction='column' marginTop={2.5} rowGap={1.5}>
      {/* <Grid item>
        <TextField
          value={email}
          label={<Message id="login.email" className={emailError ? "Field-input labelsLinks red font-weight-400" : email ? "Field-input labelsLinks blue font-weight-400" : "Field-input labelsLinks neutral-3 font-weight-400"} />}
          placeholder='Confirm your email'
          className="Field-input"
          style={{ width: '380px' }}
          InputLabelProps={{
            shrink: true,
            sx: {
              color: 'inherit',

              '&.MuiInputLabel-root.Mui-focused': {
                color: '#1280BE',
              },
            },
          }}
          error={emailError}
          helperText={emailError ? emailErrorMsg : <>&nbsp;</>}
          FormHelperTextProps={{
            sx: {
              color: emailError ? '#EF4737 !important' : '#6B7280',
              marginLeft: '0px !important'
            },
          }}
          size='medium'
          InputProps={{
            style: {
              borderRadius: '8px',
              borderColor: emailError ? '#EF4737 !important' : '#605E6B',
              fontFamily: 'DM Sans',
              height: '56px',
              color: (emailError && email) ? '#EF4737' : '#302E3E'
            },
            sx: {
              '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: '#EF4737',
              },
            }
          }}
          onChange={(e) => {
            setEmail(e.target.value)
            if (!e.target.value || !e.target.value.match(
              /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/
            )) {
              setEmailError(true);
              setEmailErrorMsg(intl.formatMessage({ id: 'error.enter-a-valid-email-address' }))
            }
            else {
              setEmailError(false);
              setEmailErrorMsg('')
            }
          }}
          onBlur={(e) => {
            if (!e.target.value) {
              setEmailError(true);
              setEmailErrorMsg(intl.formatMessage({ id: 'error.enter-a-valid-email-address' }))
            }
          }}
        />
      </Grid> */}
      <Grid item>
        <TextField
          value={name}
          label= {<Message id="ta.add.surname" className={nameError ? "Field-input labelsLinks red font-weight-400" : name ? "Field-input labelsLinks blue font-weight-400" : "Field-input labelsLinks neutral-3 font-weight-400"} />}
          placeholder='Enter your Sur Name'
          className="Field-input"
          style={{ width: '380px' }}
          InputLabelProps={{
            shrink: true,
            sx: {
              color: 'inherit',

              '&.MuiInputLabel-root.Mui-focused': {
                color: '#1280BE',
              },
            },
          }}
          error={nameError}
          helperText={nameError ? nameErrorMsg : <>&nbsp;</>}
          FormHelperTextProps={{
            sx: {
              color: nameError ? '#EF4737 !important' : '#6B7280',
              marginLeft: '0px !important'
            },
          }}
          size='medium'
          InputProps={{
            style: {
              borderRadius: '8px',
              borderColor: nameError ? '#EF4737 !important' : '#605E6B',
              fontFamily: 'DM Sans',
              height: '56px',
              color: (nameError && name) ? '#EF4737' : '#302E3E'
            },
            sx: {
              '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: '#EF4737',
              },
            }
          }}
          onChange={(e) => {
            const value = e.target.value;
            setName(value);
      
            if (!value.trim()) {
              setNameError(true); 
              setNameErrorMsg(intl.formatMessage({ id: 'ta.enter-surname' }));
            } else {
              setNameError(false);
              setNameErrorMsg('');
            }
          }}
          onBlur={(e) => {
            if (!e.target.value.trim()) {
              setNameError(true); 
              setNameErrorMsg(intl.formatMessage({ id: 'ta.enter-surname' }));
            }
          }}
        />
      </Grid>
      <Grid item>
        <TextField
          type={showPassword ? "text" : "password"}
          value={password}
          label={<Message id="login.password" className={passError ? "Field-input labelsLinks red font-weight-400" : password ? "Field-input labelsLinks blue font-weight-400" : "Field-input labelsLinks neutral-3 font-weight-400"} />}
          placeholder={intl.formatMessage({ id: 'login.enter-your-password-here' })}
          className="Field-input"
          style={{ width: '380px' }}
          InputLabelProps={{ shrink: true }}
          size='medium'
          error={passError}
          helperText={passError ? passErrorMsg : <>&nbsp;</>}
          FormHelperTextProps={{
            sx: {
              color: passError ? '#EF4737 !important' : '#6B7280',
              marginLeft: '0px !important'
            },
          }}
          InputProps={{
            style: {
              borderRadius: '8px',
              fontFamily: 'DM Sans',
              height: '56px'
            },
            sx: {
              '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: '#EF4737',
              },
            },
            endAdornment: (
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? (
                  <Visibility htmlColor={password ? '#302E3E' : "#C3C2C7"} />
                ) : (
                  <VisibilityOff htmlColor={password ? '#302E3E' : "#C3C2C7"} />
                )}
              </IconButton>
            ),
          }}
          onChange={(e) => {
            setPassword(e.target.value)
            if (!e.target.value) {
              setPassError(true);
              setPassErrorMsg(intl.formatMessage({ id: 'error.enter-a-password' }))
            }
            else {
              setPassError(false);
              setPassErrorMsg('')
            }
          }}
          onBlur={(e) => {
            if (!e.target.value) {
              setPassError(true);
              setPassErrorMsg(intl.formatMessage({ id: 'error.enter-a-password' }))
            }
          }}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              // login()
              ev.preventDefault();
            }
          }}
        />

      </Grid>
      <Grid item>
        <TextField
          type={showConfirmPass ? "text" : "password"}
          value={confirmPass}
          label={<Message id="login.confirm-password" className={confirmPassError ? "Field-input labelsLinks red font-weight-400" : confirmPass ? "Field-input labelsLinks blue font-weight-400" : "Field-input labelsLinks neutral-3 font-weight-400"} />}
          placeholder={intl.formatMessage({ id: 'login.confirm-password' })}
          className="Field-input"
          style={{ width: '380px' }}
          InputLabelProps={{ shrink: true }}
          size='medium'
          error={confirmPassError}
          helperText={confirmPassError ? confirmPassErrorMsg : <>&nbsp;</>}
          FormHelperTextProps={{
            sx: {
              color: confirmPassError ? '#EF4737 !important' : '#6B7280',
              marginLeft: '0px !important'
            },
          }}
          InputProps={{
            style: {
              borderRadius: '8px',
              fontFamily: 'DM Sans',
              height: '56px'
            },
            sx: {
              '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: '#EF4737',
              },
            },
            endAdornment: (
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowConfirmPass}
                onMouseDown={handleMouseDownConfirmPass}
                edge="end"
              >
                {showConfirmPass ? (
                  <Visibility htmlColor={confirmPass ? '#302E3E' : "#C3C2C7"} />
                ) : (
                  <VisibilityOff htmlColor={confirmPass ? '#302E3E' : "#C3C2C7"} />
                )}
              </IconButton>
            ),
          }}
          onChange={(e) => {
            setConfirmPass(e.target.value)
            if (!e.target.value) {
              setConfirmPassError(true);
              setConfirmPassErrorMsg(intl.formatMessage({ id: 'error.confirm-your-password' }));
            } else if (e.target.value !== password) {
              setConfirmPassError(true);
              setConfirmPassErrorMsg(intl.formatMessage({ id: 'error.passwords-do-not-match' }));
            } else {
              setConfirmPassError(false);
              setConfirmPassErrorMsg('');
            }
          }}
          onBlur={(e) => {
            if (!e.target.value) {
              setConfirmPassError(true);
              setConfirmPassErrorMsg(intl.formatMessage({ id: 'error.confirm-your-password' }));
            } else if (e.target.value !== password) {
              setConfirmPassError(true);
              setConfirmPassErrorMsg(intl.formatMessage({ id: 'error.passwords-do-not-match' }));
            }
          }}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              // login()
              ev.preventDefault();
            }
          }}
        />

      </Grid>
      <Grid item style={{marginBottom:'10px'}}>
        <button className='blueButton'
         disabled={loading || !email || !password || !name || !confirmPass || passError ||nameError || confirmPassError || loading || (localStorage.getItem('userType') === '1')}
          onClick={() => {
            // navigate('/dashboard')
            // dispatch(loadingActions.setLoading(true));
            // setLoginFormState('email-login')
            // localStorage.setItem('loginFormState', 'email-login')
            //   login();
            clientRegistrationCall();
          }}
          style={{ height: '35px' }}
        >{loading ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span> : 'Login to Taxmation'}</button>
      </Grid>

      <Grid item container justifyContent='center' alignItems='center'>
        <Grid item xs={4} className={classes['greyLine']}></Grid>
        <Grid item>
          <Message id="text.or-link-with-sso" className='neutral-6' />
        </Grid>
        <Grid item xs={4} className={classes['greyLine']}></Grid>
      </Grid>
      <SSOLogin
        loginFormState={loginFormState} setLoginFormState={setLoginFormState}
      //  setIsLoggedIn={setIsLoggedIn} isSSO={isSSO} setIsSSO={setIsSSO} 
      />
      {BeErrorMsg ?
        <Grid item className='errorText'>{BeErrorMsg}</Grid> :
        <></>}
      {/* <Grid item container direction="row" paddingTop={0.5} paddingBottom={0.5} justifyContent='center' alignItems='center'>
          <Grid item xs={5} className={classes['greyLine']}></Grid>
          <span className='neutral-6'><Message id="text.or" className='neutral-6' style={{ width: '40px', textAlign: 'center' }} /></span>
          <Grid item xs={5} className={classes['greyLine']}></Grid>
        </Grid>
        <SSOLogin
          loginFormState={loginFormState} setLoginFormState={setLoginFormState}
        //  setIsLoggedIn={setIsLoggedIn} isSSO={isSSO} setIsSSO={setIsSSO} 
        /> */}

    </Grid>
  );
}

export default ClientRegistration;
