import React, { useEffect, useRef, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import filterIcon from '../../../../assets/images/icons/filterIcon.png';
import activeFilterIcon from '../../../../assets/images/icons/activeFilterIcon.png';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CheckedIcon from '../../../../assets/images/icons/CheckedIcon.png';
import UnCheckedIcon from '../../../../assets/images/icons/UncheckedIcon.png';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { CircularProgress, Grid, TextField } from '@mui/material';
import classes from "../../../assets/styles/Table.module.css";
import CheckIcon from '@mui/icons-material/Check';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import selectedFilter from "../../../assets/images/icons/selectedFilter.png"
import Message from '../../../../sharedComponents/ui/Message';
import * as userRolesConst from '../../../../user-roles-consts';

export interface TableProps {
  rows: any;
  dataLength: any;
  setDataLength: any;
  paginationModel: any;
  setPaginationModel: any;
  tenants: any;
  selectedTenants: any,
  setSelectedTenants: any,
  roles: any;
  selectedRoles: any,
  setSelectedRoles: any,
  clickedRoles: any,
  setClickedRoles: CallableFunction,
  clickedTenants: any,
  setClickedTenants: CallableFunction,
  activeUserTooltip: any,
  setActiveUserTooltip: CallableFunction,
  loadMoreRows: any;
  setLoadMoreRows: any;
  setOldRows: any;
  setTake: any;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: '0px 2px 4px 0px #0000001F',
    backgroundColor: '#FEFEFF',
    borderRadius: '10px',
    padding: 0,
    // maxWidth: 'none',  // Ensure the width is not constrained
    // whiteSpace: 'nowrap',  // Prevent line breaks for content
    // overflow: 'hidden',  // Hide overflow to manage content display
    // textOverflow: 'ellipsis'
  },
}));

const Table = (props: TableProps) => {

  const { rows, tenants, selectedTenants, setSelectedTenants, roles, selectedRoles, setSelectedRoles, dataLength, setDataLength, paginationModel, setPaginationModel, activeUserTooltip, setActiveUserTooltip, clickedRoles, setClickedRoles, clickedTenants, setClickedTenants,
    loadMoreRows, setLoadMoreRows, setOldRows, setTake
  } = props

  const positions: string[] = [];
  const companies: string[] = [];
  const [filterIndex, setFilterIndex] = useState<number>();
  const [search, setSearch] = useState<string>();

  const [isRoleIconClicked, setIsRoleIconClicked] = useState(false);
  const [isCompanyIconClicked, setIsCompanyIconClicked] = useState(false);

  const [filteredRoles, setFilteredRoles] = useState<any>(roles);
  const [filteredTenants, setFilteredTenants] = useState<any>(tenants);

  const [isOpenRoles, setIsOpenRoles] = useState(false);
  // const [clickedRoles, setClickedRoles]= useState<number[]>([]);
  // const [clickedTenants, setClickedTenants]= useState<number[]>([]);
  const [isOpenCompanies, setIsOpenCompanies] = useState(false);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value.toLowerCase();
    setSearch(searchValue);
    const filtered = roles?.filter((role: any) =>
      role.name.toLowerCase()?.includes(searchValue)
    );
    setFilteredRoles(filtered);
  }

  const handleCompanySearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value.toLowerCase();
    setSearch(searchValue);
    const filtered = tenants?.filter((tenant: any) =>
      tenant?.name?.toLowerCase()?.includes(searchValue)
    );
    setFilteredTenants(filtered);
  }

  useEffect(() => {
    setFilteredRoles(roles);
  }, [roles])

  useEffect(() => {
    setFilteredTenants(tenants);
  }, [tenants])
  const columns: GridColDef[] = [
    {
      field: 'firstName', headerName: 'First name', width: 130, sortable: false,
    },
    {
      field: 'lastName', headerName: 'Last name', width: 130, sortable: false,
    },
    {
      field: 'roles', headerName: 'User Role', width: 170,
      // sortable: (activeUserTooltip !== 'roles' && !isRoleIconClicked),
      sortable: false,
      renderHeader(params: any) {
        return (
          <ClickAwayListener onClickAway={(event) => {
            setClickedRoles(selectedRoles);
            const target = event.target as HTMLElement;
            if (
              // isOpenRoles &&
              activeUserTooltip === 'roles' &&
              !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
            ) {
              // setIsOpenRoles(false);
              setActiveUserTooltip(null);
              setIsRoleIconClicked(false);
            }
          }}>
            <Grid item container className='table-header' alignItems='center' fontWeight='normal' onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              // setIsOpenRoles((prev)=>!prev)
              setActiveUserTooltip((prev: any) => (prev === 'roles' ? null : 'roles'));
              if (activeUserTooltip !== 'roles') {
                setIsRoleIconClicked(false);
              }
              // e.stopPropagation();
            }}>User Role
              <HtmlTooltip
                open={activeUserTooltip === 'roles'}
                //  onClose={()=>setIsOpenRoles(false)}
                disableHoverListener
                disableFocusListener
                title={
                  <React.Fragment>
                    <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} rowGap={0.5} maxHeight='378px' padding={2} display='inline-grid' width='307px'>
                      <Grid item>
                        <TextField
                          value={search}
                          fullWidth
                          InputProps={{
                            startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                            style: {
                              borderRadius: '35px',
                              fontFamily: 'DM Sans',
                              height: '37px',
                              backgroundColor: '#F7F9FF',
                              border: 0,
                              borderColor: '#E6E6E8',
                              fontSize: '12px'
                            },
                          }}
                          sx={{
                            '& .MuiInputBase-input::placeholder': {
                              fontWeight: 'bold',
                            },
                          }}
                          placeholder='Search'
                          onChange={handleSearch}
                        /></Grid>
                      <Grid item container maxHeight='160px' style={{ overflowX: 'hidden', overflowY: 'auto' }} rowGap={1} columnGap={2}
                        className='font-14 black normalFontWeight' padding={1}
                      >
                        {filteredRoles.map((val: any, index: number) => (
                          <Grid item container alignItems='center' key={val.id} columnGap={1}
                            // className={classes[filterIndex === index || clickedRoles?.includes(val.id) ? 'filter-selected' : 'filter-not-selected']} 
                            justifyContent='flex-start'
                            style={{
                              flexWrap: 'nowrap',
                              width: '100%',
                              fontSize: '14px',
                              marginBottom: '18px'
                            }}
                            onMouseOver={(e) => {
                              const target = e.target as HTMLElement;
                              if (!clickedRoles?.includes(val.id)) {
                                target.style.cursor = 'pointer';
                                setFilterIndex(index);
                              }
                            }}
                            onMouseOut={() => {
                              setFilterIndex(-1);
                            }}
                            onClick={() => {
                              setClickedRoles((prevRoles: any) =>
                                prevRoles.includes(val.id)
                                  ? prevRoles.filter((roleId: any) => roleId !== val.id)
                                  : [...prevRoles, val.id]
                              );
                            }}
                          >
                            <Grid className='bordered-box' style={{ borderColor: clickedRoles.includes(val.id) ? '#fff' : '#E6E6E8' }}
                              bgcolor={clickedRoles.includes(val.id) ? '#CCF0EB' : '#fff'}>
                              {
                                clickedRoles.includes(val.id) ?
                                  // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />
                                  <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                  :
                                  <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                              }
                            </Grid>
                            {/* <Grid item>{val.name}</Grid> */}
                            <Grid item>
                              {val.id === userRolesConst.SUPER_ADMIN_ID ? <Message id="filter.super-admin" className="Field-input labels font-weight-400" /> : val.id === userRolesConst.ADMIN_ID ? <Message id="filter.admin" className="Field-input labels font-weight-400" /> : val.id === userRolesConst.TAX_ADVISOR_ID ? <Message id="filter.ta" className="Field-input labels font-weight-400" /> : val.id === userRolesConst.REVIEWER_ID ? <Message id="filter.reviewer" className="Field-input labels font-weight-400" /> : val.id === userRolesConst.CLERK_ID ? <Message id="filter.clerk" className="Field-input labels font-weight-400" /> : val.id === userRolesConst.ANALYST_ID ? <Message id="filter.analyst" className="Field-input labels font-weight-400" /> : val.id === userRolesConst.CLIENT_ADMIN_ID ? <Message id="filter.client-admin" className="Field-input labels font-weight-400" /> : val.id === userRolesConst.CLIENT_AUHTORIZED_SIGNER_ID ? <Message id="filter.client-authorized-signer" className="Field-input labels font-weight-400" /> : val.id === userRolesConst.CLIENT_EMPLOYEE_ID ? <Message id="filter.client-employee" className="Field-input labels font-weight-400" /> : ''}
                            </Grid>
                          </Grid>
                        ))}

                      </Grid>
                      <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} marginLeft={1.5} style={{ cursor: clickedRoles.length > 0 ? 'pointer' : 'unset' }}
                        onClick={() => setClickedRoles([])} ><RestartAltIcon fontSize='small' />  <Message id="ta.reset-filter" className="Field-input labels font-weight-400" /></Grid>
                      <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                        <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          e.stopPropagation();
                          // setClickedRoles([]);
                          //  setIsOpenRoles(false);
                          setClickedRoles(selectedRoles);
                          setActiveUserTooltip(null);
                          setIsRoleIconClicked(false);
                        }}>
                          <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                        </button>
                        <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          setOldRows([]);
                          e.stopPropagation();
                          setSelectedRoles(clickedRoles)
                          // setIsOpenRoles(false);
                          setActiveUserTooltip(null);
                          setIsRoleIconClicked(false);
                        }}>
                          <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                        </button>

                      </Grid>
                    </Grid>
                  </React.Fragment>
                }
              >
                {/* <ArrowDropDownIcon /> */}
                <img src={selectedRoles.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                  e.stopPropagation();
                  setIsRoleIconClicked(true);
                  setClickedRoles(selectedRoles);
                  setActiveUserTooltip((prev: any) => (prev === 'roles' ? null : 'roles'));
                }} />
              </HtmlTooltip >
            </Grid >
          </ClickAwayListener>
        )
      },
    },
    {
      field: 'email', headerName: 'Email', width: 270, sortable: false,
    },
    {
      field: 'phoneNumber', headerName: 'Phone Number', width: 200, sortable: false,
    },
    // {
    //   field: 'position', headerName: 'Position', width: 150,
    //   renderHeader(params: any) {
    //     return (
    //       <Grid item container className='table-header' alignItems='center' fontWeight='normal'>Position
    //         <HtmlTooltip
    //           title={
    //             <React.Fragment>
    //               <Grid item container rowGap={1} justifyContent='center' alignItems='center' padding={1} maxHeight='250px' display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }}>
    //                 <Grid item>
    //                   <TextField
    //                     value={search}
    //                     fullWidth
    //                     InputProps={{
    //                       startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
    //                       style: {
    //                         borderRadius: '35px',
    //                         fontFamily: 'DM Sans',
    //                         height: '37px',
    //                         backgroundColor: '#F7F9FF',
    //                         border: 0,
    //                         borderColor: '#E6E6E8',
    //                         fontSize: '12px'
    //                       },
    //                     }}
    //                     sx={{
    //                       '& .MuiInputBase-input::placeholder': {
    //                         fontWeight: 'bold',
    //                       },
    //                     }}
    //                     placeholder='Search'
    //                     onChange={(e) => setSearch(e.target.value)}
    //                   /></Grid>
    //                 {positions.map((val: string, index: number) => (
    //                   <Grid item container alignItems='center' columnGap={1} className={classes[filterIndex === index ? 'filter-selected' : 'filter-not-selected']} justifyContent='flex-start'
    //                     onMouseOver={(e) => {
    //                       const target = e.target as HTMLElement;
    //                       target.style.cursor = 'pointer';
    //                       setFilterIndex(index);

    //                     }}
    //                     onMouseOut={() => {
    //                       setFilterIndex(-1);
    //                     }}
    //                   >
    //                     <Grid className='bordered-box' bgcolor={filterIndex === index ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={filterIndex === index ? '#00B69B' : '#9E9E9E'} /></Grid>
    //                     <Grid item>{val}</Grid>
    //                   </Grid>
    //                 ))}
    //                 <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} marginLeft={1.5}
    //                 ><RestartAltIcon fontSize='small' /> Remove filter</Grid>
    //               </Grid>
    //             </React.Fragment>
    //           }
    //         >
    //           <ArrowDropDownIcon />
    //         </HtmlTooltip >
    //       </Grid >
    //     )
    //   },
    // },
    {
      field: 'companyName', headerName: 'Company Name', width: 230,
      // sortable: (activeUserTooltip !== 'tenants' && !isCompanyIconClicked),
      sortable: false,
      renderHeader(params: any) {
        return (
          <ClickAwayListener onClickAway={(event) => {
            setClickedTenants(selectedTenants);
            const target = event.target as HTMLElement;
            if (
              // isOpenCompanies &&
              activeUserTooltip === 'tenants' &&
              !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
            ) {
              // setIsOpenCompanies(false);
              setActiveUserTooltip(null);
              setIsCompanyIconClicked(false);
            }
          }}>
            <Grid item container className='table-header' alignItems='center' fontWeight='normal' onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              // setIsOpenCompanies((prev)=>!prev)
              setActiveUserTooltip((prev: any) => (prev === 'tenants' ? null : 'tenants'));
              if (activeUserTooltip !== 'tenants') {
                setIsCompanyIconClicked(false);
              }
              // e.stopPropagation();
            }}>Company
              <HtmlTooltip
                open={activeUserTooltip === 'tenants'}
                //  onClose={()=>setIsOpenCompanies(false)}
                disableHoverListener
                disableFocusListener
                title={
                  <React.Fragment>
                    <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} rowGap={0.5} width='307px' maxHeight='378px' padding={2} display='inline-grid'>
                      <Grid item>
                        <TextField
                          value={search}
                          fullWidth
                          InputProps={{
                            startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                            style: {
                              borderRadius: '35px',
                              fontFamily: 'DM Sans',
                              height: '37px',
                              backgroundColor: '#F7F9FF',
                              border: 0,
                              borderColor: '#E6E6E8',
                              fontSize: '12px'
                            },
                          }}
                          sx={{
                            '& .MuiInputBase-input::placeholder': {
                              fontWeight: 'bold',
                            },
                          }}
                          placeholder='Search'
                          onChange={handleCompanySearch}
                        /></Grid>
                      <Grid item container maxHeight='160px' style={{ overflowX: 'hidden', overflowY: 'auto' }} rowGap={1} columnGap={2} className='font-14 black normalFontWeight' padding={1}>

                        {filteredTenants.map((val: any, index: number) => (
                          <Grid item container alignItems='center' key={val.id} columnGap={1}
                            //  className={classes[filterIndex === index || selectedTenants?.includes(val.id) ? 'filter-selected' : 'filter-not-selected']}
                            justifyContent='flex-start'
                            style={{
                              flexWrap: 'nowrap',
                              width: '100%',
                              fontSize: '14px',
                              marginBottom: '18px'
                            }}
                            onMouseOver={(e) => {
                              const target = e.target as HTMLElement;
                              if (!clickedTenants?.includes(val.id)) {
                                target.style.cursor = 'pointer';
                                setFilterIndex(index);
                              }
                            }}
                            onMouseOut={() => {
                              setFilterIndex(-1);
                            }}
                            onClick={(e) => {

                              setClickedTenants((prevTenants: any) =>
                                prevTenants.includes(val.id)
                                  ? prevTenants.filter((tenantId: any) => tenantId !== val.id)
                                  : [...prevTenants, val.id]
                              );
                            }}
                          >
                            <Grid className='bordered-box' style={{ borderColor: clickedTenants.includes(val.id) ? '#fff' : '#E6E6E8' }}
                              bgcolor={clickedTenants.includes(val.id) ? '#CCF0EB' : '#fff'}>
                              {
                                clickedTenants.includes(val.id) ?
                                  // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />
                                  <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                  :
                                  <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                              }
                            </Grid>
                            <Grid item>{val.name}</Grid>
                          </Grid>
                        ))}

                      </Grid>
                      <Grid item container alignItems='center' className='black font-weight-400' columnGap={2} marginLeft={1.5} style={{ cursor: clickedTenants.length > 0 ? 'pointer' : 'unset' }}
                        onClick={() => setClickedTenants([])} ><RestartAltIcon fontSize='small' />  <Message id="ta.reset-filter" className="Field-input labels font-weight-400" /></Grid>
                      <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                        <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          e.stopPropagation();
                          // setClickedTenants([]);
                          //  setIsOpenCompanies(false);
                          setActiveUserTooltip(null);
                          setIsCompanyIconClicked(false);
                          setClickedTenants(selectedTenants);
                        }}>
                          <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                        </button>
                        <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          setOldRows([]);
                          e.stopPropagation();
                          setSelectedTenants(clickedTenants)
                          // setIsOpenCompanies(false);
                          setActiveUserTooltip(null);
                          setIsCompanyIconClicked(false);
                        }}>
                          <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                        </button>

                      </Grid>
                    </Grid>
                  </React.Fragment>
                }
              >
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  {/* <ArrowDropDownIcon /> {clickedTenants.length > 0 ? <img src={selectedFilter} alt='icon' /> : <></>} */}
                  <img src={selectedTenants.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                    e.stopPropagation();
                    setIsCompanyIconClicked(true);
                    setClickedTenants(selectedTenants);
                    setActiveUserTooltip((prev: any) => (prev === 'tenants' ? null : 'tenants'));
                  }} />
                </Grid>
              </HtmlTooltip>

            </Grid >
          </ClickAwayListener>
        )
      },
    },
    {
      field: 'primaryContactCmpany', headerName: 'Primary Contact for Company', width: 300, sortable: false,
    },
    { field: 'country', headerName: 'Country', width: 200, sortable: false, },

    // {
    //   field: 'age',
    //   headerName: 'Age',
    //   type: 'number',
    //   width: 90,
    // },
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (value, row) => `${row.firstName || ''} ${row.lastName || ''}`,
    // },
  ];

  const handlePaginationModelChange = (model: any) => {
    setPaginationModel(model);
  };

  const gridRef = useRef<any>(null);

  useEffect(() => {
    console.log("rows check", rows)
    const handleScroll = (event: Event) => {
      const target = event.target as HTMLElement;

      // Check if we're inside the DataGrid's virtual scroller content
      if (target && target.classList.contains("MuiDataGrid-virtualScroller")) {
        const { scrollTop, scrollHeight, clientHeight, scrollLeft, scrollWidth } = target;

        // Log the scroll position and sizes
        //console.log(`scrollTop: ${scrollTop}, scrollHeight: ${scrollHeight}, clientHeight: ${clientHeight}`);
        // if (scrollLeft === 0 || scrollLeft === scrollWidth - clientHeight) {
          // Determine if the user has reached the bottom
          if (scrollTop + clientHeight >= scrollHeight - 1) {
            // console.log("Reached bottom of the scroll!");
            setLoadMoreRows(true);
            setOldRows(rows);
            console.log("oldd rowsss", rows)
          } else {
            //console.log("Not at the bottom yet");
          }
        // }
      }
    };

    const gridContainer = gridRef.current;
    if (gridContainer) {
      // Get the virtual scroller container from the DataGrid
      const virtualScroller = gridContainer.querySelector(
        ".MuiDataGrid-virtualScroller"
      );

      if (virtualScroller) {
        // Attach the scroll event listener
        //console.log("Found virtual scroller container");
        virtualScroller.addEventListener("scroll", handleScroll);
      } else {
        //console.log("MuiDataGrid-virtualScroller not found");
      }
    } else {
      //console.log("Grid container not found");
    }

    return () => {
      const gridContainer = gridRef.current;
      if (gridContainer) {
        const virtualScroller = gridContainer.querySelector(
          ".MuiDataGrid-virtualScroller"
        );
        if (virtualScroller) {
          // Remove the scroll event listener when the component unmounts
          virtualScroller.removeEventListener("scroll", handleScroll);
        }
      }
    };
  });

  const [tableHeight, setTableHeight] = useState(window.innerHeight - 320);

  const handleResize = () => {
    const screenHeight = window.innerHeight;
    setTableHeight(screenHeight - 320);

    if (screenHeight >= 850) {
      console.log("rows", rows)
      setTake(10)
      setLoadMoreRows(true)
    }
  };

  useEffect(() => {
    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ height: tableHeight, width: '100%' }}>
      <DataGrid
        ref={gridRef}
        rows={rows}
        columns={columns}
        // pagination
        // paginationMode='server'
        // paginationModel={paginationModel}
        // onPaginationModelChange={handlePaginationModelChange}
        // rowCount={dataLength}
        // pageSizeOptions={[25, 50, 100]}
        // checkboxSelection
        disableColumnMenu
        sx={{
          border: 2,
          height: tableHeight,
          borderColor: '#FEFEFF',
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
          },
          "& .MuiDataGrid-footerContainer": { display: "none" },
        }}
      />
      {loadMoreRows && (
        <div
          style={{
            position: "absolute",
            bottom: "40px",
            width: "100%",
            textAlign: "center",
            zIndex: 10,
          }}
        >
          <CircularProgress style={{ color: '#5D5FEF', width: '22px', height: '22px' }} />
        </div>
      )}
    </div>
  )
}

export default Table