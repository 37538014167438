import React, { useEffect, useState } from 'react';
import './App.css';
import Navigate from './routes/Navigate';
import * as CryptoJS from 'crypto-js';
import Utf8 from 'crypto-js/enc-utf8';
import axios from 'axios';
import enMessages from "./locals/english.json";
import grMessages from "./locals/german.json";
import { Locale, selectDir, selectLocale } from './app/slices/localsSlice';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { IntlProvider } from 'react-intl';
import { setIsLoggedIn, setIsCMS, setIsSubscribed, setIsSSO, setIsOtpEnabled, selectIsLoggedIn, setIsOtpVerified, selectIsOtpVerified, selectIsOtpEnabled, selectIsSSO, selectIsSubscribed, selectIsCMS, setUserType, selectUserType, setRoles, selectRoles, setCountries } from './app/slices/userDataSlice';
import QRCode from 'qrcode';
import { jwtDecode } from 'jwt-decode';
import * as userServices from "./services/user-services.proxy";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import { googleLogout } from '@react-oauth/google';

axios.defaults.baseURL = 'https://localhost:3000';
axios.defaults.withCredentials = true;
axios.defaults.headers.common['Content-Type'] = 'application/json';

const secretKey = Utf8.parse(`${process.env.REACT_APP_ENCRYPTION_SECRET_KEY}`);
const iv = Utf8.parse(`${process.env.REACT_APP_ENCRYPTION_IV}`);

export function encryptData(data: any): string {
    // console.log("data", data)
    const jsonData = JSON.stringify(data);
    const cipherText = CryptoJS.AES.encrypt(jsonData, secretKey, { iv: iv }).toString();
    return cipherText;
}

export function decryptData(ciphertext: string): any {
    const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey, { iv });
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}

// //Request interceptor for encrypting payload
// axios.interceptors.request.use(
//     (config) => {
//         if (config && config.data) {
//             config.data = encryptData(config.data);
//         }
//         return config;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );

// // Response interceptor for decrypting response
// axios.interceptors.response.use(
//     (response) => {
//         if (response && response.data) {
//             response.data = decryptData(response.data);
//         }
//         return response;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );

const getMessages = (locale: Locale) => {
    switch (locale) {
        case 'en':
            return enMessages;
        case 'gr':
            return grMessages;
    }
}

interface CustomJwtPayload {
    Roles?: string[]; // Adjust the type as necessary based on your token's structure
    // Add other properties that your token might include
}

const getUserData = userServices.getUserData;

function App() {

    const dispatch = useAppDispatch();
    const locale = useAppSelector(selectLocale);
    const dir = useAppSelector(selectDir);

    const [rolesFetching, setRolesFetching] = useState(false);

    const isLoggedIn = useAppSelector(selectIsLoggedIn);
    const isCMS = useAppSelector(selectIsCMS);
    const userType = useAppSelector(selectUserType);
    const isSubscribed = useAppSelector(selectIsSubscribed);
    const isSSO = useAppSelector(selectIsSSO);
    const isOtpEnabled = useAppSelector(selectIsOtpEnabled);
    const isOtpVerified = useAppSelector(selectIsOtpVerified);
    const userRoles = useAppSelector(selectRoles);

    const [qrCodeUrl, setqrCodeUrl] = useState<any>();

    const navigate = useNavigate();

    const getCookie = (name: string): string | undefined => {
        console.log("document.cookie", document.cookie);
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop()?.split(';').shift();
    };

    // useEffect(() => {
    //     QRCode.toDataURL('otpauth://totp/taxmation-levit.net:Taxmation?issuer=taxmation-levit.net&secret=5W32SC67CVUFRCE6J3YFJZG7HHEMGLRR&algorithm=SHA1&digits=6&period=30', (err, url) => {
    //         if (err) console.log(err)
    //         setqrCodeUrl(url)
    //       })
    // })

    useEffect(() => {

        if (isLoggedIn) {

            // console.log("document.cookie", document.cookie);
            // const token = getCookie('Access-Token');
            // console.log("token", token)
            // if (token) {
            //     console.log("yes token", token)
            //     const user = jwtDecode<CustomJwtPayload>(token);
            //     console.log("user", user)
            //     if (user.Roles) {
            //         console.log("yes user", user)
            //         const rolesString = (user.Roles).toString();
            //         localStorage.setItem('rolesString', rolesString);
            //         const rolesArrayNb: number[] = rolesString.split(',')?.map(Number)?.filter((num) => !isNaN(num));
            //         dispatch(setRoles(rolesArrayNb));
            //     }
            // }
            setRolesFetching(true);
            getUserData().then((x) => {
                const rolesString = (x.roles)?.toString();
                localStorage.setItem('rolesString', rolesString);
                dispatch(setRoles(x.roles));
                setRolesFetching(false);
                dispatch(setCountries(x.countries))
            })
        }
    }, [isLoggedIn])

    useEffect(() => {

        if (localStorage.getItem('isLoggedIn') === 'true') dispatch(setIsLoggedIn(true));
        else dispatch(setIsLoggedIn(false));

        if (localStorage.getItem('isCMS') === 'true') dispatch(setIsCMS(true))
        else dispatch(setIsCMS(false))

        if (localStorage.getItem('isSubscribed') === 'true') dispatch(setIsSubscribed(true))
        else dispatch(setIsSubscribed(false))

        if (localStorage.getItem('isSSO') === 'true') dispatch(setIsSSO(true))
        else dispatch(setIsSSO(false))

        if (localStorage.getItem('isOtpEnabled') === 'true') dispatch(setIsOtpEnabled(true))
        else dispatch(setIsOtpEnabled(false))

        if (localStorage.getItem('isOtpVerified') === 'true') dispatch(setIsOtpVerified(true))
        else dispatch(setIsOtpVerified(false))

        const userTypeFromStorage = localStorage.getItem('userType');
        if (userTypeFromStorage !== null) {
            const userType = parseInt(userTypeFromStorage, 10);
            dispatch(setUserType(userType));
        }

        const rolesFromStorage = localStorage.getItem('rolesString');
        if (rolesFromStorage) {
            const rolesArrayNb: number[] = rolesFromStorage.split(',').map(Number)?.filter((num) => !isNaN(num));
            dispatch(setRoles(rolesArrayNb));

        }
        // if (rolesFromStorage !== null) {
        //     const parsedArray = JSON.parse(rolesFromStorage);
        //     const parsedNumbers = parsedArray?.map(Number);
        //     dispatch(setRoles([parsedNumbers]));
        // }

    }, [dispatch])

    const { instance } = useMsal();

    function deleteAllCookies() {
        const cookies = document.cookie.split(";");

        for (const cookie of cookies) {
            const cookieName = cookie.split("=")[0].trim();
            // Attempt to delete the cookie with common attributes
            document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
            document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${window.location.hostname};`;
            document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.${window.location.hostname};`;
        }
    }


    const logoutMethod = () => {
        localStorage.clear();
        dispatch(setIsLoggedIn(false));
        dispatch(setIsCMS(false));
        localStorage.setItem('loginFormState', 'default')
        deleteAllCookies();
        if (localStorage.getItem('ssoType') === 'google')
            googleLogout();
        if (localStorage.getItem('ssoType') === 'microsoft') {
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/",
            });
            // instance.loginPopup(loginRequest).catch((e) => {
            //     console.log(e);
            //   });
        }
        navigate('/');
    }


    // useEffect(() => {


    //     // Set a timer for 24 hours (24 * 60 * 60 * 1000 milliseconds)
    //     const timerId = setTimeout(() => {
    //         console.log("testt")
    //         logoutMethod()
    //     }, 24 * 60 * 60 * 1000); // 24 hours in milliseconds

    //     // Clean up the timer when the component is unmounted
    //     return () => clearTimeout(timerId);

    // }, []); // Empty dependency array to run this effect only once on mount

    useEffect(() => {
        // Check if there's a saved login time in localStorage
        const savedLoginTime = localStorage.getItem("loginTime");
        
        // If login time exists, calculate the remaining time to logout
        if (savedLoginTime) {
            const currentTime = Date.now();
            const loginTime = parseInt(savedLoginTime, 10);
            const elapsedTime = currentTime - loginTime;

            // If the user has already been logged in for 24 hours, log them out immediately
            if (elapsedTime >= 24 * 60 * 60 * 1000) {
                console.log("User has been logged in for 24 hours, logging out...");
                logoutMethod();
                return;
            }

            // Otherwise, set a timer to log out after the remaining time
            const remainingTime = 24 * 60 * 60 * 1000 - elapsedTime;
            const timerId = setTimeout(() => {
                console.log("24 hours passed, logging out...");
                logoutMethod();
            }, remainingTime);

            // Cleanup the timer on component unmount
            return () => clearTimeout(timerId);

        } else {
            // No login time found, this means the user hasn't logged in yet
            console.log("User not logged in.");
        }

    }, []); // Empty dependency array to run once on mount

    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 850);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 800);
      };
  
      window.addEventListener('resize', handleResize);
  
      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    
    return (
        <>
        {isMobile ?
        <div>This website is not available on mobile screen</div>
        :
        <IntlProvider locale={locale} messages={getMessages(locale)}>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={rolesFetching}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <div dir={dir} className='App'>
            {/* <img
          src={qrCodeUrl}
          alt="qrcode url"
          width='160px'
          height='160px'
        /> */}
            <Navigate />
        </div>

    </IntlProvider>
        }
       
        </>
    );
}

export default App;
