import React, { useState, useEffect, useRef } from "react";
import { DataGrid, GridColDef, GridColType } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import filterIcon from '../../../assets/images/icons/filterIcon.png';
import activeFilterIcon from '../../../assets/images/icons/activeFilterIcon.png';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CheckedIcon from '../../../assets/images/icons/CheckedIcon.png';
import UnCheckedIcon from '../../../assets/images/icons/UncheckedIcon.png';
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { CircularProgress, Grid, TextField } from "@mui/material";
import classes from "../../../assets/styles/Table.module.css";
import CheckIcon from "@mui/icons-material/Check";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import UpdateRole from "./Dialogs/UpdateRole";
import { useIntl } from 'react-intl';
import * as userServices from "../../../services/user-services.proxy";
import * as clientServices from "../../../services/client-services.proxy";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getLoadingState, setLoading } from "../../../app/slices/loadingSlice";
import {
  getCallStatus,
  getErrorMsg,
  setCallStatus,
  setErrorMsg,
} from "../../../app/slices/apiCallSlice";
import DeleteDialog from "../../../sharedComponents/Dialogs/DeleteDialog";
import * as statusesConsts from "../../../status-consts";
import selectedFilter from "../../../assets/images/icons/selectedFilter.png";
import * as userRolesConst from "../../../user-roles-consts";
import { selectRoles } from "../../../app/slices/userDataSlice";
import Message from "../../../sharedComponents/ui/Message";
import ConfigureAccess from "./Dialogs/ConfigureAccess";
import accessIcon from "../../../assets/images/icons/accessIcon.png";
import deleteIcon from "../../../assets/images/icons/delete.png";
import moment from "moment";
import { FastfoodOutlined } from "@mui/icons-material";


const updateTMRole = userServices.updateTMRole;
const deleteClient = clientServices.deleteClient;
const getClientData = clientServices.getClientData;
const updateClientModule = clientServices.updateClientModule;
const getUserData = userServices.getUserData


export interface TableProps {
  rows: any;
  setRows: any;
  dataLength: any;
  setDataLength: any;
  roles: any;
  selectedRoles: any;
  setSelectedRoles: any;
  modify: any;
  setModify: any;
  statusId: number[],
  setStatusId: CallableFunction;
  paginationModel: any,
  setPaginationModel: any,
  clickedStatus: number[],
  setClickedStatus: CallableFunction,
  clickedRoles: number[],
  setClickedRoles: CallableFunction,
  isOpenRoles: boolean;
  setIsOpenRoles: CallableFunction;
  isOpenStatus: boolean;
  setIsOpenStatus: CallableFunction;
  activeTooltip: any;
  setActiveTooltip: CallableFunction;
  // pageNb: any,
  // setPageNb: any,
  // pageSize: any,
  // setPageSize: any,
  loadMoreRows: any;
  setLoadMoreRows: any;
  setOldRows: any;
  setTake: any;
}


const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: "0px 2px 4px 0px #0000001F",
    backgroundColor: "#FEFEFF",
    borderRadius: "10px",
    padding: 0,
  },
}));

const ClientsTable = (props: TableProps) => {
  const { rows, setRows, roles, selectedRoles, setSelectedRoles, modify, setModify, statusId, setStatusId, dataLength, paginationModel, setPaginationModel, clickedStatus, setClickedStatus, clickedRoles, setClickedRoles, isOpenRoles, setIsOpenRoles, isOpenStatus, setIsOpenStatus, activeTooltip, setActiveTooltip,
    loadMoreRows, setLoadMoreRows, setOldRows, setTake
  } =
    props;

  const planStatuses: number[] = [1, 2, 3]
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const clientRoles = roles?.filter((r: { typeId: number; }) => r.typeId === 2);
  const [filteredRoles, setFilteredRoles] = useState<any>(clientRoles);


  const [isRoleIconClicked, setIsRoleIconClicked] = useState(false);
  const [isStatusIconClicked, setIsStatusIconClicked] = useState(false);

  //store states
  const loading = useAppSelector(getLoadingState);
  const errorMsg = useAppSelector(getErrorMsg);
  const callStatus = useAppSelector(getCallStatus);
  const userRoles = useAppSelector(selectRoles);

  const [filterIndex, setFilterIndex] = useState<number>();
  const [search, setSearch] = useState<string>("");

  //update role
  const [openUpdateRoleDialog, setOpenUpdateRoleDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [selectedUser, setSelectedUser] = useState<any>();
  const [selectedUserToDelete, setSelectedUserToDelete] = useState<any>();

  const [selectedClient, setSelectedClient] = useState<any>();

  const [openConfigureAccessDialog, setOpenConfigureAccessDialog] = useState(false);

  const [taxAdvisorClients, setTaxAdvisorClients] = useState([]);
  const [modules, setModules] = useState([]);
  const [userDataLoading, setUserDataLoading] = useState(false);


  const extractRolesIds = (rolesString: string) => {
    if (rolesString.length > 0) {
      const roleNames = rolesString?.split(",").map((name) => name.trim());

      const ids = roles
        .filter((role: { name: string }) => roleNames?.includes(role.name))
        .map((role: { id: any }) => role.id);

      return ids;
    }
    else return []

  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value.toLowerCase();
    setSearch(searchValue);
    const filtered = clientRoles?.filter((role: any) =>
      role.name.toLowerCase()?.includes(searchValue)
    );
    setFilteredRoles(filtered);
  }

  useEffect(() => {
    console.log("user roles", userRoles)
  })

  useEffect(() => {
    getUserData().then((x) => {
      let tmpClients = x.clients.map((client: any) => ({
        ...client,
        expand: true
      }));
      setTaxAdvisorClients(tmpClients);
      // setTaxAdvisorClients(x.clients);
      setModules(x.modules)
    })
  }, [])

  const columns: GridColDef[] = [
    ...(userRoles?.includes(userRolesConst.ADMIN_ID) ? [{
      field: 'actions',
      type: 'actions' as GridColType,
      width: 10,
      sortable: false,
      renderCell: (params: { row: { id: any; email: any; roles: any; }; }) => (
        <HtmlTooltip
          placement='bottom-start'
          title={
            <React.Fragment>
              <Grid item container rowGap={1.5} padding={1.5} maxWidth='260px' className="Field-input labelsLinks font-weight-400 font-14">

                <Grid item container direction='row' className="black Field-input labelsLinks font-weight-400 font-14" alignItems='center' columnGap={1}
                  onClick={() => {
                    //setOldRows([])
                    setSelectedClient(params.row);
                    setOpenConfigureAccessDialog(true)
                    getClientDataCall(params.row.id);
                  }}
                >
                  <img src={accessIcon} /> <Message id="text.edit-company-module" className="Field-input labelsLinks font-weight-400" />
                </Grid>

                <Grid item container direction='row' className='red labels pointerText' alignItems='center' columnGap={1}
                  onClick={() => {
                    //setOldRows([])
                    setSelectedUserToDelete(() => ({
                      userId: params.row.id,
                      email: params.row.email,
                      roles: params.row.roles,
                    }));
                    setOpenDeleteDialog(true);
                  }}
                >
                  {/* <DeleteOutlineIcon htmlColor='#EF4737' fontSize='small' /> */}
                  <img src={deleteIcon} width='20px' height='20px' />
                  <Message id="ta.delete" className="Field-input labelsLinks font-weight-400" />
                </Grid>
              </Grid>
            </React.Fragment>
          }
        >
          <Grid item style={{ cursor: 'pointer' }}><MoreVertIcon htmlColor='#93919a' /></Grid>
        </HtmlTooltip>
      ),
    }] : []),
    // {
    //   field: 'actions',
    //   type: 'actions',
    //   width: 10,
    //   sortable: false,
    //   renderCell: (params) => (
    //     <HtmlTooltip
    //       title={
    //         <React.Fragment>
    //           <Grid item container rowGap={1.5} padding={1.5} maxWidth='120px'>
    //             {/* {userRoles.includes(userRolesConst.SUPER_ADMIN_ID || userRolesConst.ADMIN_ID) ? */}
    //             {/* <Grid item container direction='row' className='neutral-4 labels pointerText' alignItems='center' columnGap={1}
    //               onClick={() => {
    //                 setSelectedUser(() => ({
    //                   userId: params.row.userId,
    //                   email: params.row.email,
    //                   roles: extractRolesIds(params.row.roles),
    //                   type: 2,
    //                 }));
    //                 setOpenUpdateRoleDialog(true);
    //               }}

    //             >
    //               <EditOutlinedIcon htmlColor='#605e6b' fontSize='small' /><Message id="ta.edit.edit-role" className="Field-input labelsLinks font-weight-400" />
    //             </Grid> */}
    //             {/* :
    //             <></>} */}


    //             {/* {userRoles.includes(userRolesConst.SUPER_ADMIN_ID) ? */}
    //             <Grid item container direction='row' className='red labels pointerText' alignItems='center' columnGap={1}
    //               onClick={() => {
    //                 setSelectedUserToDelete(() => ({
    //                   userId: params.row.id,
    //                   email: params.row.email,
    //                   roles: params.row.roles,
    //                 }));
    //                 setOpenDeleteDialog(true);
    //               }}
    //             ><DeleteOutlineIcon htmlColor='#EF4737' fontSize='small' /><Message id="ta.delete" className="Field-input labelsLinks font-weight-400" /></Grid>
    //             {/* :
    //             <></>
    //           } */}

    //           </Grid>
    //         </React.Fragment>
    //       }
    //     >
    //       <Grid item style={{ cursor: 'pointer' }}><MoreVertIcon htmlColor='#93919a' /></Grid>
    //     </HtmlTooltip>
    //   ),
    // },
    { field: "name", headerName: intl.formatMessage({ id: 'user.profile.user-name' }), width: 180, sortable: false, },
    { field: 'email', headerName: intl.formatMessage({ id: 'user.profile.email' }), width: 270, sortable: false, },
    {
      field: 'roles', headerName: intl.formatMessage({ id: 'user.user-role' }), width: 190,
      // sortable: (activeTooltip !== 'roles' && !isRoleIconClicked),
      sortable: false,
      renderHeader(params: any) {
        return (
          <ClickAwayListener onClickAway={(event) => {
            setClickedRoles(selectedRoles);
            const target = event.target as HTMLElement;
            if (
              activeTooltip === 'roles' &&
              !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
            ) {
              // setIsOpenRoles(false);
              setActiveTooltip(null);
              setIsRoleIconClicked(false);
            }
          }}>
            <Grid item container className='table-header' alignItems='center' fontWeight='normal'
              onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                // setIsOpenRoles((prev:any)=>!prev)
                setActiveTooltip((prev: any) => (prev === 'roles' ? null : 'roles'));
                if (activeTooltip !== 'roles') {
                  setIsRoleIconClicked(false);
                }
                // e.stopPropagation();
              }}>
              <Message id="user.user-role" className="Field-input labels font-weight-400" />
              <HtmlTooltip
                open={activeTooltip === 'roles'}
                // onClose={()=>setIsOpenRoles(false)}
                disableHoverListener
                disableFocusListener
                title={
                  <React.Fragment>
                    <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} rowGap={0.5} maxHeight='378px' width='307px' padding={2} display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }}>
                      <Grid item>
                        <TextField
                          value={search}
                          fullWidth
                          InputProps={{
                            startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                            style: {
                              borderRadius: '35px',
                              fontFamily: 'DM Sans',
                              height: '37px',
                              backgroundColor: '#F7F9FF',
                              border: 0,
                              borderColor: '#E6E6E8',
                              fontSize: '12px',
                              marginBottom: '10px'
                            },
                          }}
                          sx={{
                            '& .MuiInputBase-input::placeholder': {
                              fontWeight: 'bold',
                            },
                          }}
                          placeholder={intl.formatMessage({ id: 'text.search' })}
                          onChange={handleSearch}
                        /></Grid>

                      {filteredRoles.map((val: any, index: number) => (
                        <Grid item container alignItems='center' key={val.id} columnGap={2}
                          //  className={classes[filterIndex === index || selectedRoles?.includes(val.id) ? 'filter-selected' : 'filter-not-selected']}
                          className='font-14 black normalFontWeight'
                          justifyContent='flex-start'
                          style={{
                            flexWrap: 'nowrap',
                            width: '100%',
                            fontSize: '14px',
                            marginBottom: '18px'
                          }}
                          onMouseOver={(e) => {
                            const target = e.target as HTMLElement;
                            if (!clickedRoles?.includes(val.id)) {
                              target.style.cursor = 'pointer';
                              setFilterIndex(index);
                            }
                          }}
                          onMouseOut={() => {
                            setFilterIndex(-1);
                          }}
                          onClick={(e) => {
                            // if (!clickedRoles?.includes(val.id)) {
                            //   setClickedRoles((prevTenants: any) => [...prevTenants, val.id]);
                            // }
                            setClickedRoles((prevSelectedRoles: any) => {
                              if (prevSelectedRoles.includes(val.id)) {
                                return prevSelectedRoles.filter((id: any) => id !== val.id);
                              }
                              else {
                                return [...prevSelectedRoles, val.id];
                              }
                            })
                          }}
                        >
                          <Grid className='bordered-box'
                            style={{ borderColor: clickedRoles?.includes(val.id) ? '#fff' : '#E6E6E8' }}
                            bgcolor={clickedRoles?.includes(val.id) ? '#CCF0EB' : '#fff'}>
                            {
                              clickedRoles?.includes(val.id) ?
                                // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />
                                <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                :
                                <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                            }
                          </Grid>
                          {/* <Grid className='bordered-box' bgcolor={filterIndex === index || selectedRoles?.includes(val.id) ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={filterIndex === index || selectedRoles?.includes(val.id) ? '#00B69B' : '#9E9E9E'} /></Grid> */}
                          {/* <Grid item>{val.name}</Grid> */}
                          <Grid item>
                            {val.id === userRolesConst.CLIENT_ADMIN_ID ? <Message id="filter.client-admin" className="Field-input labels font-weight-400" /> : val.id === userRolesConst.CLIENT_AUHTORIZED_SIGNER_ID ? <Message id="filter.client-authorized-signer" className="Field-input labels font-weight-400" /> : val.id === userRolesConst.CLIENT_EMPLOYEE_ID ? <Message id="filter.client-employee" className="Field-input labels font-weight-400" /> : ''}
                          </Grid>
                        </Grid>
                      ))}
                      <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} marginLeft={0.5} style={{ cursor: clickedRoles.length > 0 ? 'pointer' : 'unset' }}
                        onClick={() => setClickedRoles([])} ><RestartAltIcon fontSize='small' />  <Message id="ta.reset-filter" className="Field-input labels font-weight-400" /></Grid>

                      <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                        <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          e.stopPropagation();
                          // setClickedRoles([]);
                          //  setIsOpenRoles(false);
                          setClickedRoles(selectedRoles);
                          setActiveTooltip(null);
                          setIsRoleIconClicked(false);
                        }}>
                          <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                        </button>
                        <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          e.stopPropagation();
                          setSelectedRoles(clickedRoles)
                          // setIsOpenRoles(false);
                          setActiveTooltip(null);
                          setIsRoleIconClicked(false);
                        }}>
                          <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                        </button>

                      </Grid>
                    </Grid>
                  </React.Fragment>
                }
              >
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  {/* <ArrowDropDownIcon
              style={{
                transform: isOpenRoles ? 'rotate(180deg)' : 'rotate(0deg)', 
                transition: 'transform 0.3s ease', 
              }}
            />  */}
                  <img src={selectedRoles.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                    e.stopPropagation();
                    setIsRoleIconClicked(true);
                    setClickedRoles(selectedRoles);
                    setActiveTooltip((prev: any) => (prev === 'roles' ? null : 'roles'));
                  }} />
                  {/* {selectedRoles.length > 0 ? <img src={selectedFilter} alt='icon' /> : <></>} */}
                </Grid>
              </HtmlTooltip>
            </Grid >
          </ClickAwayListener>
        )
      },
    },

    { field: 'lastActive', headerName: intl.formatMessage({ id: 'user.last-active' }), width: 200, sortable: false, },
    {
      field: 'statusId', headerName: intl.formatMessage({ id: 'user.status' }), width: 170,
      // sortable: (activeTooltip !== 'status' && !isStatusIconClicked),
      sortable: false,
      renderHeader(params: any) {
        return (
          <ClickAwayListener onClickAway={(event) => {
            setClickedStatus(statusId);
            const target = event.target as HTMLElement;
            if (
              activeTooltip === 'status' &&
              !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
            ) {
              // setIsOpenStatus(false);
              setActiveTooltip(null);
              setIsStatusIconClicked(false);
            }
          }}>
            <Grid item container className='table-header' alignItems='center' fontWeight='normal' onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              // setIsOpenStatus((prev:any) => !prev)
              // e.stopPropagation();
              setActiveTooltip((prev: any) => (prev === 'status' ? null : 'status'));
              if (activeTooltip !== 'status') {
                setIsStatusIconClicked(false);
              }
            }}>
              <Message id="user.status" className="Field-input labels font-weight-400" />
              <HtmlTooltip
                open={activeTooltip === 'status'}
                // onClose={() => setIsOpenStatus(false)}
                disableHoverListener
                disableFocusListener
                title={
                  <React.Fragment>
                    <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} direction='row' rowGap={0.5} width='307px' maxHeight='378px' padding={2}>
                      {planStatuses.map((val: number, index: number) => (
                        <Grid item container alignItems='center' columnGap={2}
                          // className={classes[filterIndex === index || (statusId === 1 && val === 'Active') || (statusId === 2 && val === 'Inactive') || (statusId === 3 && val === 'Invited') ? 'filter-selected' : 'filter-not-selected']}
                          justifyContent='flex-start'
                          className='font-14 black normalFontWeight'
                          style={{
                            flexWrap: 'nowrap',
                            width: '100%',
                            fontSize: '14px',
                            marginBottom: '18px'
                          }}
                          // onMouseOver={(e) => {
                          //   const target = e.target as HTMLElement;
                          //   if ((clickedStatus !== 1 && val === 1) || (clickedStatus !== 2 && val === 2) || (clickedStatus !== 3 && val === 3)) {
                          //     target.style.cursor = 'pointer';
                          //     setFilterIndex(index);
                          //   }
                          // }}
                          onMouseOver={(e) => {
                            const target = e.target as HTMLElement;
                            if (
                              !(clickedStatus.includes(1) && val === 1) &&
                              !(clickedStatus.includes(2) && val === 2) &&
                              !(clickedStatus.includes(3) && val === 3)
                            ) {
                              target.style.cursor = 'pointer';
                              setFilterIndex(index);
                            }
                          }}

                          onMouseOut={() => {
                            setFilterIndex(-1);
                          }}
                          // onClick={() => {
                          //   if (val === 1) setClickedStatus(prevStatus => (prevStatus === 1 ? 0 : 1))
                          //   if (val === 2) setClickedStatus(prevStatus => (prevStatus === 2 ? 0 : 2))
                          //   if (val === 3) setClickedStatus(prevStatus => (prevStatus === 3 ? 0 : 3))

                          // }}
                          onClick={() => {
                            setClickedStatus((prevSelectedStatus: any) => {
                              if (prevSelectedStatus.includes(val)) {
                                return prevSelectedStatus.filter((status: any) => status !== val);
                              } else {
                                return [...prevSelectedStatus, val];
                              }
                            });
                          }}

                        >
                          <Grid className='bordered-box'
                            style={{
                              borderColor: ((clickedStatus.includes(1) && val === 1) || (clickedStatus.includes(2) && val === 2) || (clickedStatus.includes(3) && val === 3)) ? '#fff' : '#E6E6E8',
                            }}
                            bgcolor={((clickedStatus.includes(1) && val === 1) || (clickedStatus.includes(2) && val === 2) || (clickedStatus.includes(3) && val === 3)) ? '#CCF0EB' : '#fff'}>
                            {
                              ((clickedStatus.includes(1) && val === 1) || (clickedStatus.includes(2) && val === 2) || (clickedStatus.includes(3) && val === 3)) ?
                                // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />

                                <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                :
                                <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                            }
                          </Grid>

                          {/* <Grid className='bordered-box' bgcolor={
                          filterIndex === index ||
                            (statusId === 1 && val === 'Active') ||
                            (statusId === 2 && val === 'Inactive') ? '#CCF0EB' :
                            (statusId === 3 && val === 'Invited') ? '#ff9900' :
                              '#E0E0E0'
                        }><CheckIcon
                            fontSize='small'
                            htmlColor={
                              filterIndex === index ||
                                (statusId === 1 && val === 'Active') ||
                                (statusId === 2 && val === 'Inactive') ? '#00B69B' :
                                (statusId === 3 && val === 'Invited') ? '#000000' :
                                  '#9E9E9E'
                            } /></Grid> */}
                          <Grid item>
                            {
                              val === 1 ? <Message id="filter.active" className="Field-input labels font-weight-400" /> :
                                val === 2 ? <Message id="filter.inactive" className="Field-input labels font-weight-400" /> :
                                  val === 3 ? <Message id="filter.invited" className="Field-input labels font-weight-400" /> : ''
                            }
                          </Grid>
                        </Grid>
                      ))}
                      {/* <Grid item container alignItems='center' columnGap={1} className={classes[statusId === 1 ? 'filter-selected' : 'filter-not-selected']} justifyContent='flex-start'
                                  onMouseOver={() => {
                                      if (statusId !== 1) {
                                          setFilterIconIndex('active')
                                      }
                                  }}
                                  onMouseOut={() => {
                                      setFilterIconIndex('')
                                  }}
                              >
                                  <Grid className='bordered-box' bgcolor={statusId === 1 || filterIconIndex === 'active' ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={statusId === 1 || filterIconIndex === 'active' ? '#00B69B' : '#9E9E9E'} /></Grid>
                                  <Grid item>Active</Grid>
                              </Grid>
                              <Grid item container alignItems='center' columnGap={1} className={classes[statusId === 2 ? 'filter-selected' : 'filter-not-selected']} justifyContent='flex-start'
                                  onMouseOver={() => {
                                      if (statusId !== 2) {
                                          setFilterIconIndex('in-active')
                                      }
                                  }}
                                  onMouseOut={() => {
                                      setFilterIconIndex('')
                                  }}
                              >
                                  <Grid className='bordered-box' bgcolor={statusId === 2 || filterIconIndex === 'in-active' ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={statusId === 2 || filterIconIndex === 'in-active' ? '#00B69B' : '#9E9E9E'} /></Grid>
                                  <Grid item>Inactive</Grid>
                              </Grid> */}
                      <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} style={{ cursor: clickedStatus ? 'pointer' : 'unset' }} marginLeft={0.5}
                        onClick={() => {
                          setClickedStatus([]);
                        }}><RestartAltIcon fontSize='small' /> <Message id="ta.reset-filter" className="Field-input labels font-weight-400" /></Grid>
                      {/* <Grid item container width='80px' marginLeft={7}>
                                  <button className='blueButton' disabled={loading}>Ok</button></Grid> */}
                      <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                        <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          e.stopPropagation();
                          // setClickedStatus([]);
                          //  setIsOpenStatus(false);
                          setClickedStatus(statusId);
                          setActiveTooltip(null);
                          setIsStatusIconClicked(false);
                        }}>
                          <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                        </button>
                        <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          e.stopPropagation();
                          setStatusId(clickedStatus)
                          // setIsOpenStatus(false);
                          setActiveTooltip(null);
                          setIsStatusIconClicked(false);
                        }}>
                          <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                        </button>

                      </Grid>
                    </Grid>
                  </React.Fragment>
                }
              >
                {/* <ArrowDropDownIcon
              style={{
                transform: isOpenStatus ? 'rotate(180deg)' : 'rotate(0deg)', 
                transition: 'transform 0.3s ease', 
              }}
            /> */}
                <img src={statusId?.length ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                  e.stopPropagation();
                  setIsStatusIconClicked(true);
                  setClickedStatus(statusId);
                  setActiveTooltip((prev: any) => (prev === 'status' ? null : 'status'));
                }} />
              </HtmlTooltip >
            </Grid >
          </ClickAwayListener>
        )
      },
      renderCell: (params) => {
        return (
          <Grid item container direction='row' style={{ marginTop: '10px', height: '15px' }} columnGap={1.5}>
            <Grid item className={params.value === statusesConsts.ACTIVE_STATUS_ID ? 'active-status' : params.value === statusesConsts.INVITED_STATUS_ID ? 'invited-status' : params.value === statusesConsts.IN_ACTIVE_STATUS_ID ? 'not-active-status' : ''} width='70px'>
              {params.value === statusesConsts.ACTIVE_STATUS_ID ? <Message id="filter.active" className="Field-input labels font-weight-400" /> : params.value === statusesConsts.IN_ACTIVE_STATUS_ID ? <Message id="filter.in-active" className="Field-input labels font-weight-400" /> : params.value === statusesConsts.INVITED_STATUS_ID ? <Message id="filter.invited" className="Field-input labels font-weight-400" /> : ''}
            </Grid>
          </Grid>
        );
      },
    },
    { field: 'phoneNumber', headerName: intl.formatMessage({ id: 'user.profile.phone-number' }), width: 200, sortable: false, },
    { field: 'primaryContactPerson', headerName: intl.formatMessage({ id: 'user.primary-contact-person' }), width: 200, sortable: false, },
    { field: 'country', headerName: intl.formatMessage({ id: 'user.country' }), width: 200, sortable: false, },
    // { field: 'position', headerName: 'Position', width: 200 },
    {
      field: 'creationDate', headerName: intl.formatMessage({ id: 'user.creation-date' }), width: 200, sortable: false,
      renderCell: (params) => {
        return (
          <span>{moment(params.row.creationDate).format('DD.MM.YYYY')}</span>
        )
      }
    },
  ];

  const updateClientRoleCall = () => {
    dispatch(setLoading(true));
    updateTMRole({
      userId: selectedUser.userId,
      roles: selectedUser.roles,
    }).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus("Fail"));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
        }, 4500);
      } else {
        dispatch(setCallStatus("Pass"));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
          setOpenUpdateRoleDialog(false);
          dispatch(setModify(!modify));
        }, 1000);
      }
      dispatch(setLoading(false));
    });
  };

  const deleteClientCall = () => {
    dispatch(setLoading(true));
    deleteClient(selectedUserToDelete.userId).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus("Fail"));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
        }, 4500);
      } else {
        dispatch(setCallStatus("Pass"));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
          setOpenDeleteDialog(false);
          //dispatch(setModify(!modify));
          setRows(rows.filter((r: any) => r.id !== selectedUserToDelete.userId))
        }, 1000);
      }
      dispatch(setLoading(false));
    });
  };

  const handlePaginationModelChange = (model: any) => {
    // const adjustedPage = Math.max(1, model.page);
    // // Catch the new page number
    // setPageNb(adjustedPage);
    // setPageSize(model.pageSize);
    // Update the pagination model
    setPaginationModel(model);
  };

  // const updateClientModuleCall = () => {
  //   dispatch(setLoading(true));
  //   updateClientModule(selectedClient.id).then((x) => {
  //     if (x.ErrorMessage) {
  //       dispatch(setErrorMsg(x.ErrorMessage));
  //       dispatch(setCallStatus("Fail"));
  //       setTimeout(() => {
  //         dispatch(setErrorMsg(""));
  //         dispatch(setCallStatus(""));
  //       }, 4500);
  //     } else {
  //       dispatch(setCallStatus("Pass"));
  //       setTimeout(() => {
  //         dispatch(setErrorMsg(""));
  //         dispatch(setCallStatus(""));
  //         setOpenDeleteDialog(false);
  //         dispatch(setModify(!modify));
  //       }, 1000);
  //     }
  //     dispatch(setLoading(false));
  //   });
  // };

  const getClientDataCall = (clientId: string) => {
    setUserDataLoading(true);
    getClientData(clientId).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus("Fail"));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
        }, 4500);
      } else {
        dispatch(setCallStatus("Pass"));
        setSelectedClient(x);
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
        }, 1000);
      }
      setUserDataLoading(false);
    });
  };

  const gridRef = useRef<any>(null);

  useEffect(() => {
    console.log("rows check", rows)
    const handleScroll = (event: Event) => {
      const target = event.target as HTMLElement;

      // Check if we're inside the DataGrid's virtual scroller content
      if (target && target.classList.contains("MuiDataGrid-virtualScroller")) {
        const { scrollTop, scrollHeight, clientHeight, scrollLeft, scrollWidth } = target;

        // Log the scroll position and sizes
        //console.log(`scrollTop: ${scrollTop}, scrollHeight: ${scrollHeight}, clientHeight: ${clientHeight}`);
        // if (scrollLeft === 0 || scrollLeft === scrollWidth - clientHeight) {
          // Determine if the user has reached the bottom
          if (scrollTop + clientHeight >= scrollHeight - 1) {
            // console.log("Reached bottom of the scroll!");
            setLoadMoreRows(true);
            setOldRows(rows);
            console.log("oldd rowsss", rows)
          } else {
            //console.log("Not at the bottom yet");
          }
        // }
      }
    };

    const gridContainer = gridRef.current;
    if (gridContainer) {
      // Get the virtual scroller container from the DataGrid
      const virtualScroller = gridContainer.querySelector(
        ".MuiDataGrid-virtualScroller"
      );

      if (virtualScroller) {
        // Attach the scroll event listener
        //console.log("Found virtual scroller container");
        virtualScroller.addEventListener("scroll", handleScroll);
      } else {
        //console.log("MuiDataGrid-virtualScroller not found");
      }
    } else {
      //console.log("Grid container not found");
    }

    return () => {
      const gridContainer = gridRef.current;
      if (gridContainer) {
        const virtualScroller = gridContainer.querySelector(
          ".MuiDataGrid-virtualScroller"
        );
        if (virtualScroller) {
          // Remove the scroll event listener when the component unmounts
          virtualScroller.removeEventListener("scroll", handleScroll);
        }
      }
    };
  });

  const [tableHeight, setTableHeight] = useState(window.innerHeight - 320);

  const handleResize = () => {
    const screenHeight = window.innerHeight;
    setTableHeight(screenHeight - 320);
    console.log("screenHeight", screenHeight)

    if (screenHeight >= 850) {
      console.log("rows", rows)
      setTake(10)
      setLoadMoreRows(true)
    }

  };

  useEffect(() => {
    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <div style={{ height: tableHeight, width: "100%" }}>
      <DataGrid
        ref={gridRef}
        rows={rows}
        columns={columns}
        // pagination
        // paginationMode='server'
        // paginationModel={paginationModel}
        // onPaginationModelChange={handlePaginationModelChange} // Handle pagination changes
        // rowCount={dataLength} // Total number of rows for server-side pagination
        // pageSizeOptions={[25, 50, 100]}
        // checkboxSelection
        disableColumnMenu
        sx={{
          border: 2,
          height: tableHeight,
          borderColor: '#FEFEFF',
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
          },
          "& .MuiDataGrid-footerContainer": { display: "none" },
        }}
      />

      {loadMoreRows && (
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            width: "100%",
            textAlign: "center",
            zIndex: 10,
          }}
        >
          <CircularProgress style={{ color: '#5D5FEF', width: '22px', height: '22px' }} />
        </div>
      )}


      {selectedUser ? (
        <UpdateRole
          openUpdateRoleDialog={openUpdateRoleDialog}
          setOpenUpdateRoleDialog={setOpenUpdateRoleDialog}
          user={selectedUser}
          setUser={setSelectedUser}
          roles={roles}
          confirmAction={updateClientRoleCall}
          loading={loading}
          callStatus={callStatus}
          errorMsg={errorMsg}
        />
      ) : (
        <></>
      )}

      {selectedUserToDelete ? (
        <DeleteDialog
          openDeleteDialog={openDeleteDialog}
          setOpenDeleteDialog={setOpenDeleteDialog}
          loading={loading}
          callStatus={callStatus}
          errorMsg={errorMsg}
          object={selectedUserToDelete}
          confirmAction={deleteClientCall}
          type='client'
        />
      ) : (
        <></>
      )}
      <ConfigureAccess
        openConfigureAccessDialog={openConfigureAccessDialog}
        setOpenConfigureAccessDialog={setOpenConfigureAccessDialog}
        user={selectedClient}
        setUser={setSelectedClient}
        modules={modules}
        taxAdvisorClients={taxAdvisorClients}
        setTaxAdvisorClients={setTaxAdvisorClients}
        type='Update Client'
        userDataLoading={userDataLoading}
      />



    </div>
  );
};

export default ClientsTable;
