import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import Message from './Message';
import overdueIcon from "../../assets/images/icons/overdueIcon.png";
import newActivityIcon from "../../assets/images/icons/newActivityIcon.png";

interface TaskCardProps {
  title: string;
  count: number;
  icon: React.ElementType;
  gradientColors: [string, string];
  backGroundColor: [string, string];
  status: number[];
  onCardClick: (status: number[], isOpenCard: boolean, isFilterByNewActivity: boolean) => void;
  isSelected: boolean;
  isOpenCard: boolean;
  isFilterByNewActivity: boolean
}

const TaskCard: React.FC<TaskCardProps> = ({ title, count, icon: Icon, gradientColors, backGroundColor, status, onCardClick, isSelected, isOpenCard, isFilterByNewActivity }) => {

  const [isHovered, setIsHovered] = useState(false);


  return (

    <Grid container 
    sx={{
      position: 'relative' as const,
      borderRadius: '14px',
      padding: '2px', // Thin border thickness
      background: `linear-gradient(to bottom, ${backGroundColor[0]}, ${backGroundColor[1]})`,
      overflow: 'hidden',
      // width: '215px',
      flex: '1 1 auto',
      minWidth: '160px',
      maxWidth:'215px',
      height: '184px',
      cursor: 'pointer',
      '&:hover': {
      background: `linear-gradient(to bottom, ${backGroundColor[0]}, ${backGroundColor[1]})`,
    },
    }}
    onClick={() => onCardClick(status, isOpenCard, isFilterByNewActivity)}
      onMouseOver={(e) => {
        setIsHovered(true);
      }}
      onMouseOut={() => {
        setIsHovered(false)
      }}>
      <Grid container direction='column' 
      // style={styles.card}
      sx={{
        position: 'relative' as const,
        borderRadius: '14px', // Slightly smaller radius to fit within the gradient border
        padding: '12px',
        background: isSelected || isHovered? `linear-gradient(to bottom, ${backGroundColor[0]}, ${backGroundColor[1]})` : '#F7F9FF', // Inner content background
        textAlign: 'center' as const,
        width: '215px', // Maximum width for larger screens
        minWidth: '160px',
      }}
      >
        <Grid container direction='row' alignItems='flex-start' justifyContent='space-between'>
          <Grid item sx={{ fontWeight: '400', fontSize: 'clamp(16px, 1.6vw, 21px)', }}>{title}</Grid>
          <Grid item>
            {title === "Overdue Tasks" ?
              <img src={overdueIcon} width='36px' height='36px' alt='icon'/>
              : isFilterByNewActivity ?
              <img src={newActivityIcon} width='clamp(22px, 1.7vw, 26px)' height='24px' alt='icon'/>
              
              :
              <Icon fontSize='medium' style={{ width: 'clamp(22px, 1.7vw, 26px)', height: '26px' }} />
            }
          </Grid>
        </Grid>
        <Grid container direction='row' alignItems='flex-end' justifyContent='space-between' paddingTop='41px' sx={{flexWrap:'nowrap'}}>
          <Grid item sx={{ fontWeight: 'bold', fontSize: 'clamp(60px, 1.7vw, 65px)',marginBottom:'-16px' }}>{count}</Grid>
          <Grid item
            sx={{
              color: 'text.secondary',
              // position: 'absolute',
              // bottom: '30px',
              // right: '16px',
              '&:hover': {
                textDecoration: 'underline',
                cursor: 'pointer',
              },
            }}
          >
            <Message id="task.view-tasks" className='Field-input font-weight-400 labelsLinks neutral-5 underline-text' />
          </Grid>
        </Grid>
      </Grid>
    </Grid>

  );
};

export default TaskCard;

