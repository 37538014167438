import React, { useEffect, useState } from 'react';
import { CircularProgress, ClickAwayListener, Grid, MenuItem, TextField } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useAppSelector } from '../../../app/hooks';
import { getCallStatus, getErrorMsg } from '../../../app/slices/apiCallSlice';
import { getLoadingState } from '../../../app/slices/loadingSlice';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { useNavigate } from 'react-router-dom';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import classes from "../../../assets/styles/User.module.css";
import CheckIcon from '@mui/icons-material/Check';
import CheckedIcon from '../../../assets/images/icons/CheckedIcon.png';
import UnCheckedIcon from '../../../assets/images/icons/UncheckedIcon.png';
import Message from '../../../sharedComponents/ui/Message';
import { useIntl } from 'react-intl';
import * as UserRolesConsts from "../../../user-roles-consts";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ConfigureAccess from './Dialogs/ConfigureAccess';
import userAccessIcon from "../../../assets/images/icons/userAccessIcon.png";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import * as taskConsts from "../../../task-consts";
import CloseIcon from '@mui/icons-material/Close';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: '0px 2px 4px 0px #0000001F',
    backgroundColor: '#FEFEFF',
    borderRadius: '10px',
    padding: 0,
    color: 'black',
  },
}));

export interface UserProps {
  activeTab: any;
  setActiveSection: any;
  addedTms: any;
  setAddedTms: any;
  roles: any;
  addedClients: any;
  setAddedClients: any;
  taxAdvisorClients: any;
  setTaxAdvisorClients: any;
  modules: any;
}

const AddUser = (props: UserProps) => {
  const { activeTab, setActiveSection, addedTms, setAddedTms, roles, addedClients, setAddedClients, taxAdvisorClients, modules,
    setTaxAdvisorClients
  } = props

  const navigate = useNavigate();
  const intl = useIntl();

  const [isHovered, setIsHovered] = useState(false);
  const [isCompaniesHovered, setIsCompaniesHovered] = useState(false);

  const [lineIndex, setLineIndex] = useState<number>();
  const [tmpCurrentTab, setTmpCurrentTab] = useState<string>();

  const [emailError, setEmailError] = useState<boolean>(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState<string>('');

  const [nameError, setNameError] = useState<boolean>(false);
  const [clientError, setClientError] = useState<boolean>(false);

  const [deleteIconHover, setDeleteIconHover] = useState<boolean>(false);

  const [listError, setListError] = useState<boolean>(false);

  const loading = useAppSelector(getLoadingState);
  const errorMsg = useAppSelector(getErrorMsg);
  const callStatus = useAppSelector(getCallStatus);

  const [, updateState] = React.useState({});

  const [openComapniesTooltip, setOpenComapniesTooltip] = useState(false);

  const [openConfigureAccessDialog, setOpenConfigureAccessDialog] = useState(false);
  const [user, setUser] = useState();

  const [isRolesOpen, setIsRolesOpen] = useState(false);
  const [isModuleOpen, setIsModuleOpen] = useState(false);

  // Create a memoized callback to force re-render
  const forceUpdate = React.useCallback(() => {
    // Updating state with an empty object to trigger a re-render
    updateState({});
  }, []);

  const addUserObject = () => {
    console.log("adding")
    const listToCheck = activeTab === 'Tms' ? addedTms : addedClients;

    const allUsersValid = listToCheck.every((user: { email: string; roles: string | any[]; }) =>
      user.email.trim() !== "" && user.roles.length > 0
    );
    if (allUsersValid) {
      const tmUser = {
        id: addedTms.length + 1, // Unique id for the new user
        roles: [],
        email: "",
        accessModules: localStorage.getItem('userType') === '1' ? [] : null,
        modules: localStorage.getItem('userType') === '1' ? null : [taskConsts.GENERAL_ID]
      };
      const clientUser = {
        id: addedClients.length + 1, // Unique id for the new user
        roles: [UserRolesConsts.CLIENT_ADMIN_ID],
        email: "",
        name: "",
        clientNumber: "",
        modules: [taskConsts.GENERAL_ID],
        salutation: ""
      };
      if (activeTab === 'Tms') {
        setAddedTms([...addedTms, tmUser]);
      }
      if (activeTab === 'Clients') {
        setAddedClients([...addedClients, clientUser]);
      }

    }
    else setListError(true)
  }

  return (
    <Grid item container direction='column' padding={3} rowGap={1} justifyContent='center'>
      <Grid item className='black desktop-header-5'>
        {activeTab === 'Tms' ? <Message id="text.add-your-team" className="Field-input desktop-header-5 font-weight-400" />
          :
          <Message id="text.add-your-clients" className="Field-input desktop-header-5 font-weight-400" />
        } </Grid>
      <Grid item className='black labels'> <Message id="text.add-multiple-users" className="Field-input labels font-weight-400" /></Grid>
      {/* <Grid item container direction='row' className='black labels' columnGap={2} marginTop={3}>
        <Grid item xs={activeTab === 'Tms' ?4:2.5}>  {activeTab === 'Tms' ? <Message id="ta.add.role" className="Field-input labels font-weight-400" />
          :
          <Message id="ta.add.level-of-access" className="Field-input labels font-weight-400" />
        } </Grid>
        {activeTab === 'Clients' ?
        <>
          <Grid item xs={2.5}><Message id="ta.add.name" className="Field-input labels font-weight-400" /></Grid>
          <Grid item xs={2}>Client Number *</Grid>
          </>
          :
          <></>
        }
        <Grid item xs={3}> <Message id="ta.add.email" className="Field-input labels font-weight-400" /></Grid>
      </Grid> */}
      {activeTab === 'Tms' ?

        addedTms.map((val: any, index: number) => (
          <Grid item container key={val.id} direction='row' columnSpacing={2} rowGap={2} alignItems='flex-start'>
            <Grid item container direction='column' xs={3}>
              <Grid item> <Message id="ta.add.email" className="Field-input font-16 font-weight-400 black" /> </Grid>
              <Grid item>
                <TextField
                  value={val.email}
                  placeholder={intl.formatMessage({ id: 'login.enter-your_email-here' })}
                  className="Field-input"
                  InputLabelProps={{ shrink: true }}
                  error={lineIndex === index && tmpCurrentTab === 'Tms' ? emailError : false}
                  helperText={emailError && lineIndex === index && tmpCurrentTab === 'Tms' ? emailErrorMsg : ''}
                  size='medium'
                  InputProps={{
                    style: {
                      borderRadius: '12px',
                      fontFamily: 'DM Sans',
                      height: '50px',
                    },
                  }}
                  onChange={(e) => {
                    setLineIndex(index);
                    setTmpCurrentTab('Tms');
                    val.email = e.target.value;
                    forceUpdate();
                    console.log("tms", addedTms)
                    if (!e.target.value || !e.target.value.match(
                      /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/
                    )) {
                      setEmailError(true);
                      setEmailErrorMsg(intl.formatMessage({ id: 'error.enter-a-valid-email-address' }))
                    }
                    else {
                      setEmailError(false);
                      setEmailErrorMsg('')
                    }
                  }}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setEmailError(true);
                      setEmailErrorMsg(intl.formatMessage({ id: 'error.enter-a-valid-email-address' }))
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container direction='column' xs={8}>
              <Grid item> <Message id="ta.add.role" className="Field-input font-16 font-weight-400 black" /></Grid>

              <ClickAwayListener onClickAway={(event) => {
                const target = event.target as HTMLElement;
                if (
                  isRolesOpen
                ) {
                  setIsRolesOpen(false);
                }
              }}>
                <Grid item>
                  <HtmlTooltip
                    open={isRolesOpen}
                    disableHoverListener
                    onClose={() => setIsRolesOpen(false)}
                    title={
                      <React.Fragment>
                        <Grid item container rowGap={0.5} maxHeight='378px' padding={2} display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }}>

                          <Grid item container maxHeight='228px' display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto', width: '257px' }} rowGap={1}>
                            {/* <Grid item container rowGap={1} maxHeight='100px' padding={1} display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }}> */}
                            {roles?.filter((r: { typeId: number; }) => (localStorage.getItem('userType') === '1' ? r.typeId === 1 : r.typeId === 2))?.map((role: any, index: number) => (
                              <Grid item container
                                // style={{ cursor: 'pointer' }} 
                                alignItems='center' key={role.id} columnGap={2}
                                justifyContent='flex-start'
                                style={{
                                  flexWrap: 'nowrap',
                                  width: '100%',
                                  fontSize: '14px',
                                  marginBottom: '18px'
                                }}
                                // <Grid item container alignItems='center' key={role.id} columnGap={1} className={classes[lineIndex === index || val.roles?.includes(role.id) ? 'filter-selected' : 'filter-not-selected']} justifyContent='flex-start'
                                onMouseOver={(e) => {
                                  const target = e.target as HTMLElement;
                                  // if (!val.roles?.includes(role.id)) {
                                  target.style.cursor = 'pointer';
                                  setLineIndex(index);
                                  // }
                                }}
                                onMouseOut={() => {
                                  setLineIndex(-1);
                                }}
                                onClick={(e) => {
                                  if (!val.roles?.includes(role.id)) {
                                    const updatedRoles = [...val.roles, role.id];
                                    val.roles = updatedRoles;
                                    forceUpdate();
                                    // setSelectedRoles((prevTenants: any) => [...prevTenants, val.id]);
                                  }
                                  else {
                                    const updatedRoles = val.roles.filter((r: { id: any; }) => r !== role.id)
                                    val.roles = updatedRoles;
                                    forceUpdate();
                                  }
                                }}
                              >
                                <Grid className='bordered-box'
                                  style={{ borderColor: val.roles?.includes(role.id) ? '#fff' : '#E6E6E8' }}
                                  bgcolor={val.roles?.includes(role.id) ? '#CCF0EB' : '#fff'}>
                                  {
                                    val.roles?.includes(role.id) ?
                                      // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '13px', height: '12px' }} />

                                      <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                      :
                                      <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                                  }
                                </Grid>
                                <Grid item className='font-14 font-weight-400 '>{role.name}</Grid>


                                {/* <Grid item>{role.name}</Grid>
                              <Grid className='bordered-box' bgcolor={lineIndex === index || val.roles?.includes(role.id) ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={lineIndex === index || val.roles?.includes(role.id) ? '#00B69B' : '#9E9E9E'} /></Grid> */}
                              </Grid>
                            ))}

                          </Grid>
                          <Grid item container xs={12} alignItems='center' columnGap={1} style={{ cursor: 'pointer' }}
                            className='normalFontWeight'
                            onClick={() => {
                              val.roles = []
                              forceUpdate();
                            }}>
                            <RestartAltIcon fontSize='small' /> Reset
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    }
                  >
                    <Grid item container className='textfield' columnGap={1} rowGap={1}>
                      {/* <ControlPointIcon htmlColor='#C3C2C7' style={{ cursor: 'pointer' }} onClick={() => { setIsRolesOpen((prev: any) => !prev); }} /> */}
                      <ControlPointIcon onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}
                        htmlColor={isHovered ? '#93919A' : isRolesOpen ? '#5D5FEF' : '#C3C2C7'} style={{ cursor: 'pointer' }} onClick={() => { setIsRolesOpen((prev: any) => !prev); }} />
                      {val.roles.map((role: any) => (
                        <Grid item key={role} className={classes['roleBox']}>{roles?.find((r: { id: any; }) => r.id === role)?.name} <CloseIcon sx={{ fontSize: 18, marginLeft: '3px', cursor: 'pointer' }} onClick={() => {
                          val.roles = val.roles.filter((r: any) => r !== role)
                          forceUpdate()
                        }} />
                        </Grid>
                      ))}
                    </Grid>

                  </HtmlTooltip >

                </Grid>
              </ClickAwayListener>
            </Grid>
            <Grid item xs={0.5} marginTop={4}>
              {val.email && val.roles.length > 0 ?
                <CheckCircleOutlineRoundedIcon htmlColor='rgba(0, 182, 155, 1)' />
                :
                <></>
              }
            </Grid>

            <HtmlTooltip
              title={
                <React.Fragment>
                  <Grid item container rowGap={1.5} padding={1.5} maxWidth='170px'>

                    <Grid item container alignItems='center' columnGap={0.5} style={{ cursor: 'pointer' }} paddingTop={1}
                      onMouseOver={(e) => {
                        setLineIndex(index);
                      }}
                      onMouseOut={() => {
                        setLineIndex(-1);
                      }}
                      onClick={() => {
                        const duplicateUser = { ...val, id: addedTms.length + 1, email: "" }
                        setAddedTms([...addedTms, duplicateUser]);
                      }}
                      className='black label'>
                      <ContentCopyIcon
                        htmlColor='#93919A'
                        fontSize='small'
                      //  htmlColor={lineIndex === index && deleteIconHover && activeTab === 'Tms' ? "#EF4737" : "#93919a"}
                      />
                      Duplicate current user
                    </Grid>

                    <Grid item container alignItems='center' columnGap={0.5} style={{ cursor: 'pointer' }} paddingTop={1}
                      onMouseOver={(e) => {
                        setLineIndex(index);
                        setDeleteIconHover(true);
                      }}
                      onMouseOut={() => {
                        setLineIndex(-1);
                        setDeleteIconHover(false);
                      }}
                      onClick={() => {
                        setAddedTms((prevTms: any[]) => prevTms.filter(tm => tm.id !== val.id));
                      }}
                      className='red label'>
                      <DeleteOutlineIcon
                        htmlColor='#EF4737'
                        fontSize='small'
                      //  htmlColor={lineIndex === index && deleteIconHover && activeTab === 'Tms' ? "#EF4737" : "#93919a"}
                      />
                      Delete current user
                    </Grid>

                  </Grid>
                </React.Fragment>
              }
            >
              <Grid item xs={0.5} style={{ cursor: 'pointer' }} marginTop={4}><MoreVertIcon htmlColor='#93919a' /></Grid>
            </HtmlTooltip>

            {val.email && val.roles.length > 0 ?
              <>


                <Grid item container xs={12} direction='column' rowGap={1}>
                  <Grid item className="Field-input font-16 font-weight-400 black" style={{ display: 'flex' }}><Message id="text.configure-access" className='Field-input font-weight-400 mobile-paragraph' /><span style={{ marginLeft: '5px', marginTop: '2px' }}>{val.email} </span> </Grid>
                  <Grid item container className='textfield' columnGap={1} rowGap={1} style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setLineIndex(index);
                      setOpenComapniesTooltip(!openComapniesTooltip)
                    }
                    }
                  >
                    {(localStorage.getItem('userType') === '1' ? val.accessModules : val.modules)?.map((c: any) => (
                      <Grid item key={c.clientId} className={classes['roleBox']}>
                        {localStorage.getItem('userType') === '1' ? c.name : modules?.find((m: any) => m.id === c)?.name}
                        {c !== 1 && (
                          <CloseIcon sx={{ fontSize: 18, marginLeft: '3px', cursor: 'pointer' }} onClick={() => {
                            const updatedModules = (localStorage.getItem('userType') === '1' ? val.accessModules : val.modules)
                              .filter((mod: any) => mod !== c);

                            if (localStorage.getItem('userType') === '1') {
                              val.accessModules = updatedModules;
                            } else {
                              val.modules = updatedModules;
                            }
                            forceUpdate();
                          }} />
                        )}
                      </Grid>
                    ))}
                    {/* {val.accessModules?.map((m:any) => (
                  <Grid item>{m.name}</Grid>
                ))} */}
                    <ClickAwayListener onClickAway={(event) => {
                      const target = event.target as HTMLElement;
                      if (
                        openComapniesTooltip
                      ) {
                        setOpenComapniesTooltip(false);
                      }
                    }}>
                      <Grid item>
                        <HtmlTooltip
                          open={openComapniesTooltip}
                          // onClose={() => {
                          //   setOpenComapniesTooltip(false)
                          // }}
                          title={
                            <React.Fragment>
                              <Grid item container rowGap={0.5} maxHeight='378px' padding={2} display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }} onClick={(e) => e.stopPropagation()}>
                                <Grid item container maxHeight='228px' display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto', width: '257px' }} rowGap={0.5}>
                                  {(localStorage.getItem('userType') === '1' ? taxAdvisorClients : modules)?.map((i: any, index: number) => (
                                    <Grid item container alignItems='center' key={i.id} columnGap={2} rowGap={1}
                                      justifyContent='flex-start'
                                      style={{
                                        flexWrap: 'nowrap',
                                        width: '100%',
                                        fontSize: '14px',
                                        marginBottom: '18px'
                                      }}
                                      // onMouseOver={(e) => {
                                      //   const target = e.target as HTMLElement;
                                      //   // if (!val.roles?.includes(role.id)) {
                                      //   target.style.cursor = 'pointer';
                                      //   setLineIndex(index);
                                      //   // }
                                      // }}
                                      // onMouseOut={() => {
                                      //   setLineIndex(-1);
                                      // }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        if (localStorage.getItem('userType') === '1') {
                                          if (!val.accessModules?.find((x: { clientId: any; }) => x.clientId === i.id)) {
                                            const updatedAccess = [...val.accessModules, { clientId: i.id, name: i.name, modules: [taskConsts.GENERAL_ID] }];
                                            val.accessModules = updatedAccess;
                                            forceUpdate();
                                            // setSelectedRoles((prevTenants: any) => [...prevTenants, val.id]);
                                          }
                                          else {
                                            const updatedAccess = val.accessModules.filter((r: { clientId: any; }) => r.clientId !== i.id)
                                            val.accessModules = updatedAccess;
                                            forceUpdate();
                                          }
                                        }

                                        if (localStorage.getItem('userType') === '2') {
                                          if (!val.modules?.find((x: any) => x === i.id)) {
                                            const updatedModules = [...val.modules, i.id];
                                            val.modules = updatedModules;
                                            forceUpdate();
                                            // setSelectedRoles((prevTenants: any) => [...prevTenants, val.id]);
                                          }
                                          else {
                                            if (i.id !== taskConsts.GENERAL_ID) {
                                              const updatedModules = val.modules.filter((m: any) => m !== i.id)
                                              val.modules = updatedModules;
                                              forceUpdate();
                                            }
                                          }
                                        }

                                      }}
                                    >
                                      <Grid className='bordered-box'
                                        style={{ cursor: localStorage.getItem('userType') === '2' && i.id === taskConsts.GENERAL_ID ? 'unset' : 'pointer' }}

                                        bgcolor={
                                          ((localStorage.getItem('userType') === '1' && val.accessModules?.find((x: { clientId: any; }) => x.clientId === i.id)) || (localStorage.getItem('userType') === '2' && val.modules?.find((m: any) => m === i.id))) ? '#CCF0EB' : '#fff'}>
                                        {
                                          ((localStorage.getItem('userType') === '1' && val.accessModules?.find((x: { clientId: any; }) => x.clientId === i.id)) || (localStorage.getItem('userType') === '2' && val.modules?.find((m: any) => m === i.id))) ?
                                            // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '13px', height: '12px' }} />

                                            <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                            :
                                            <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                                        }
                                      </Grid>
                                      <Grid item className='font-14 font-weight-400 '>{i.name}</Grid>

                                    </Grid>
                                  ))}
                                  <Grid item container xs={12} alignItems='center' columnGap={1} style={{ cursor: 'pointer' }}
                                    className='normalFontWeight'
                                    onClick={() => {
                                      if (localStorage.getItem('userType') === '1') val.accessModules = [];
                                      else val.modules = [];
                                      forceUpdate();
                                    }}>
                                    <RestartAltIcon fontSize='small' /> Reset
                                  </Grid>
                                </Grid>


                                {/* <Grid item container direction='row' justifyContent='flex-end' marginTop={1} marginBottom={2} columnSpacing={1}>
                        <Grid item xs={6}
                          onClick={() => {
                            setOpenComapniesTooltip(false)

                          }}>
                          <button className='greyButton'><Message id="button.cancel" className='labels-extra-bold Field-input labelsLinks' /></button>
                        </Grid>
                        <Grid item xs={6}>
                          <button
                            className='blueButton'
                            disabled={val.accessModules?.length === 0}
                            onClick={() => setOpenComapniesTooltip(false)}
                          >
                            Ok
                          </button>
                        </Grid>
                      </Grid> */}


                              </Grid>
                            </React.Fragment>
                          }
                        >
                          <ControlPointIcon onMouseEnter={() => setIsCompaniesHovered(true)} onMouseLeave={() => setIsCompaniesHovered(false)}
                            htmlColor={isCompaniesHovered ? '#93919A' : openComapniesTooltip ? '#5D5FEF' : '#C3C2C7'} style={{ cursor: 'pointer' }} onClick={() => {
                              setOpenComapniesTooltip((prev) => !prev);
                            }} />
                        </HtmlTooltip>
                      </Grid>
                    </ClickAwayListener>
                  </Grid>


                </Grid>



              </>
              :
              <></>
            }
            {
              localStorage.getItem('userType') === '1' && val.email && val.roles.length > 0 && val.accessModules.length > 0 ?
                <>

                  {val.accessModules.some((item: { modules: string | any[]; }) => item.modules.length > 0) ?
                    <Grid item container direction='column' rowGap={1}>

                      <Grid item container direction='row'>
                        <Grid item xs={4} className='bold black'> Company </Grid>
                        <Grid item xs={8} className='bold black'> Modules</Grid>
                      </Grid>

                      {val.accessModules?.map((a: any) => (
                        <Grid item container direction='row' key={a.clientId}>
                          <Grid item xs={4} className=' black'> {a.name} </Grid>
                          <Grid item xs={8} className=' black'>
                            {a.modules?.map((m: any, index: any) => (
                              <span>{modules.find((x: any) => x.id === m)?.name} {index === a.modules?.length - 1 ? "" : ","} </span>
                            ))}
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                    :
                    <></>
                  }


                  <Grid item>
                    <button
                      className='greenAccessButton'
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setUser(val)
                        setOpenConfigureAccessDialog(true)
                      }}
                    >
                      <img src={userAccessIcon} />
                      Apply Access Configuration
                    </button> </Grid>
                </>
                :
                <></>
            }




            {
              index !== addedTms.length - 1 ?
                <Grid item className='greyLine' xs={12} marginTop={1.5} marginBottom={1.5}></Grid>

                :
                <></>
            }

          </Grid >
        ))
        :
        addedClients.map((val: any, index: number) => (
          <Grid item container key={val.id} direction='row' columnGap={2} alignItems='flex-start'>
            <Grid item container direction='column' xs={2}>
              <Grid item>  <Message id="ta.add.level-of-access" className="Field-input black font-weight-400" /></Grid>
              <Grid item container className='textfield' columnGap={1} rowGap={1} alignItems='center'>
                {val.roles.map((role: any) => (
                  <Grid item key={role} className={classes['roleBox']}>{roles?.find((r: { id: any; }) => r.id === role)?.name}</Grid>
                ))}
                {/* <Grid item>
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Grid item container rowGap={1} maxHeight='100px' padding={1} display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }}>
                        {roles?.filter((r: { typeId: number; }) => r.typeId === 2)?.map((role: any, index: number) => (
                          <Grid item container alignItems='center' key={role.id} columnGap={1} className={classes[lineIndex === index || val.roles?.includes(role.id) ? 'filter-selected' : 'filter-not-selected']} justifyContent='flex-start'
                            onMouseOver={(e) => {
                              const target = e.target as HTMLElement;
                              // if (!val.roles?.includes(role.id)) {
                              target.style.cursor = 'pointer';
                              setLineIndex(index);
                              // }
                            }}
                            onMouseOut={() => {
                              setLineIndex(-1);
                            }}
                            onClick={(e) => {
                              if (!val.roles?.includes(role.id)) {
                                const updatedRoles = [...val.roles, role.id];
                                val.roles = updatedRoles;
                                forceUpdate();
                                // setSelectedRoles((prevTenants: any) => [...prevTenants, val.id]);
                              }
                              else {
                                const updatedRoles = val.roles.filter((r: { id: any; }) => r !== role.id)
                                val.roles = updatedRoles;
                                forceUpdate();
                              }
                            }}
                          >
                            <Grid className='bordered-box' bgcolor={lineIndex === index || val.roles?.includes(role.id) ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={lineIndex === index || val.roles?.includes(role.id) ? '#00B69B' : '#9E9E9E'} /></Grid>
                            <Grid item>{role.name}</Grid>
                          </Grid>
                        ))}

                      </Grid>
                    </React.Fragment>
                  }
                >
                  <ControlPointIcon htmlColor='#C3C2C7' style={{ cursor: 'pointer' }} />
                </HtmlTooltip >
              </Grid> */}
              </Grid>
            </Grid>
            <Grid item container direction='column' xs={1}>
              <Grid item><Message id="user.salutation" className='labels neutral-9' /></Grid>
              <Grid item style={{ marginTop: '3px',maxWidth:'75px' }}>
                <TextField
                  select
                  value={val.salutation}
                  placeholder={intl.formatMessage({ id: 'ta.enter-surname' })}
                  className="Field-input"
                  InputLabelProps={{ shrink: true }}
                  // error={lineIndex === index && tmpCurrentTab === 'Clients' ? nameError : false}
                  // helperText={nameError && lineIndex === index && tmpCurrentTab === 'Clients' ? intl.formatMessage({ id: 'error.enter-a-name' }) : ''}
                  size='medium'
                  InputProps={{
                    style: {
                      borderRadius: '12px',
                      fontFamily: 'DM Sans',
                      height: '50px',
                    },
                  }}
                  onChange={(e) => {
                    setLineIndex(index);
                    setTmpCurrentTab('Clients');
                    val.salutation = e.target.value;
                    forceUpdate();
                    // if (!e.target.value) {
                    //   setNameError(true);
                    // }
                    // else {
                    //   setNameError(false);
                    // }
                  }}
                // onBlur={(e) => {
                //   if (!e.target.value) {
                //     setNameError(true);
                //   }
                // }}
                >
                  <MenuItem value='Female' style={{ maxWidth: '100%', textOverflow: 'ellipsis', whiteSpace:'nowrap', overflow: 'hidden'}}>Geehrte Frau</MenuItem>
                  <MenuItem value='Male'  style={{maxWidth: '100%', textOverflow: 'ellipsis', whiteSpace: 'nowrap',overflow: 'hidden'}}>Geehrter Herr</MenuItem>
                  <MenuItem value='Divers'  style={{ maxWidth: '100%', textOverflow: 'ellipsis', whiteSpace:'nowrap', overflow: 'hidden'}}>Guten Tag</MenuItem>
                </TextField>

              </Grid>
            </Grid>

            <Grid item container direction='column' xs={2.5}>
              <Grid item><Message id="ta.add.surname" className="Field-input black font-weight-400" /></Grid>
              <Grid item>
                <TextField
                  value={val.name}
                  placeholder={intl.formatMessage({ id: 'ta.enter-surname' })}
                  className="Field-input"
                  InputLabelProps={{ shrink: true }}
                  error={lineIndex === index && tmpCurrentTab === 'Clients' ? nameError : false}
                  helperText={nameError && lineIndex === index && tmpCurrentTab === 'Clients' ? intl.formatMessage({ id: 'error.enter-a-name' }) : ''}
                  size='medium'
                  InputProps={{
                    style: {
                      borderRadius: '12px',
                      fontFamily: 'DM Sans',
                      height: '50px',
                    },
                  }}
                  onChange={(e) => {
                    setLineIndex(index);
                    setTmpCurrentTab('Clients');
                    val.name = e.target.value;
                    forceUpdate();
                    if (!e.target.value) {
                      setNameError(true);
                    }
                    else {
                      setNameError(false);
                    }
                  }}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setNameError(true);
                    }
                  }}
                />
              </Grid>
            </Grid>

            <Grid item container direction='column' xs={2}>
              <Grid item className="Field-input black font-weight-400" > <Message id="ta.add.client.nb" className="Field-input black font-weight-400" /></Grid>
              <Grid item >
                <TextField
                  value={val.clientNumber}
                  placeholder='Enter Client Nb'
                  className="Field-input"
                  InputLabelProps={{ shrink: true }}
                  error={lineIndex === index && tmpCurrentTab === 'Clients' ? clientError : false}
                  helperText={clientError && lineIndex === index && tmpCurrentTab === 'Clients' ? 'Enter Client Number' : ''}
                  size='medium'
                  InputProps={{
                    style: {
                      borderRadius: '12px',
                      fontFamily: 'DM Sans',
                      height: '50px',
                    },
                  }}
                  onChange={(e) => {
                    setLineIndex(index);
                    setTmpCurrentTab('Clients');
                    val.clientNumber = e.target.value;
                    forceUpdate();
                    if (!e.target.value) {
                      setClientError(true);
                    }
                    else {
                      setClientError(false);
                    }
                  }}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setClientError(true);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container direction='column' xs={3}>
              <Grid item> <Message id="ta.add.email" className="Field-input black font-weight-400" /></Grid>
              <Grid item>
                <TextField
                  value={val.email}
                  placeholder={intl.formatMessage({ id: 'login.enter-your_email-here' })}
                  className="Field-input"
                  InputLabelProps={{ shrink: true }}
                  error={lineIndex === index && tmpCurrentTab === 'Clients' ? emailError : false}
                  helperText={emailError && lineIndex === index && tmpCurrentTab === 'Clients' ? emailErrorMsg : ''}
                  size='medium'
                  InputProps={{
                    style: {
                      borderRadius: '12px',
                      fontFamily: 'DM Sans',
                      height: '50px',
                    },
                  }}
                  onChange={(e) => {
                    setLineIndex(index);
                    setTmpCurrentTab('Clients');
                    val.email = e.target.value;
                    forceUpdate();
                    if (!e.target.value || !e.target.value.match(
                      /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/
                    )) {
                      setEmailError(true);
                      setEmailErrorMsg(intl.formatMessage({ id: 'error.enter-a-valid-email-address' }))
                    }
                    else {
                      setEmailError(false);
                      setEmailErrorMsg('')
                    }
                  }}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setEmailError(true);
                      setEmailErrorMsg(intl.formatMessage({ id: 'error.enter-a-valid-email-address' }))
                    }
                  }}
                />
              </Grid>
            </Grid>


            <Grid item style={{ cursor: 'pointer' }} paddingTop={1} marginTop={3.5}
              onMouseOver={(e) => {
                setLineIndex(index);
                setDeleteIconHover(true);
              }}
              onMouseOut={() => {
                setLineIndex(-1);
                setDeleteIconHover(false);
              }}
              onClick={() => {
                setAddedClients((prevTms: any[]) => prevTms.filter(tm => tm.id !== val.id));
              }}>
              <DeleteOutlineIcon htmlColor={lineIndex === index && deleteIconHover && activeTab === 'Clients' ? "#EF4737" : "#93919a"} />
            </Grid>

            <Grid item container direction='column' xs={10.7} marginTop={2}>
              <Grid item className="Field-input black font-weight-400" style={{ display: 'flex' }}> <Message id="text.configure-module-access" className='Field-input font-weight-400 mobile-paragraph' /><span style={{ marginLeft: '5px', marginTop: '2px' }}>{val.email}</span>  </Grid>
              <ClickAwayListener onClickAway={(event) => {
                const target = event.target as HTMLElement;
                if (
                  isModuleOpen
                ) {
                  setIsModuleOpen(false);
                }
              }}>
                <Grid item>
                  <HtmlTooltip
                    open={isModuleOpen}
                    disableHoverListener
                    onClose={() => setIsModuleOpen(false)}
                    title={
                      <React.Fragment>
                        <Grid item container rowGap={0.5} maxHeight='228px' padding={1.5} display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto', width: '257px' }}>
                          {modules?.map((module: any, index: number) => (
                            <Grid item container alignItems='center' key={module.id} columnGap={1}
                              className="font-14 normalFontWeight"
                              //className={classes[lineIndex === index || val.roles?.includes(module.id) ? 'filter-selected' : 'filter-not-selected']} 
                              justifyContent='flex-start'
                              style={{
                                flexWrap: 'nowrap',
                                width: '100%',
                                fontSize: '14px',
                                marginBottom: '18px'
                              }}
                              onMouseOver={(e) => {
                                const target = e.target as HTMLElement;
                                // if (!val.roles?.includes(role.id)) {
                                if (module.id === taskConsts.GENERAL_ID) target.style.cursor = 'unset';
                                else target.style.cursor = 'pointer';
                                setLineIndex(index);
                                // }
                              }}
                              onMouseOut={() => {
                                setLineIndex(-1);
                              }}
                              onClick={(e) => {
                                if (!val.modules?.includes(module.id)) {
                                  const updatedModules = [...val.modules, module.id];
                                  val.modules = updatedModules;
                                  forceUpdate();
                                  // setSelectedRoles((prevTenants: any) => [...prevTenants, val.id]);
                                }
                                else {
                                  if (module.id !== taskConsts.GENERAL_ID) {
                                    const updatedModules = val.modules.filter((m: { id: any; }) => m !== module.id)
                                    val.modules = updatedModules;
                                    forceUpdate();
                                  }
                                }
                              }}
                            >
                              <Grid className='bordered-box'
                                //bgcolor={lineIndex === index || val.modules?.includes(module.id) ? '#CCF0EB' : '#E0E0E0'}
                                style={{ borderColor: val.modules?.includes(module.id) ? '#fff' : '#E6E6E8', cursor: module.id === 1 ? 'unset' : 'pointer' }}
                                bgcolor={val.modules?.includes(module.id) ? '#CCF0EB' : '#fff'}
                              >
                                {/* <CheckIcon fontSize='small' htmlColor={lineIndex === index || val.roles?.includes(module.id) ? '#00B69B' : '#9E9E9E'} /> */}
                                {
                                  val.modules?.includes(module.id) ?
                                    // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '13px', height: '12px' }} />
                                    <img src={CheckedIcon} style={{ width: '20px', height: '20px', cursor: module.id !== 1 ? 'pointer' : 'unset' }} />
                                    :
                                    <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                                }
                              </Grid>
                              <Grid item>{module.name}</Grid>

                            </Grid>
                          ))}

                        </Grid>
                      </React.Fragment>
                    }
                  >

                    <Grid item container className='textfield' columnGap={1} rowGap={1}>
                      <ControlPointIcon onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}
                        htmlColor={isHovered ? '#93919A' : isModuleOpen ? '#5D5FEF' : '#C3C2C7'} style={{ cursor: 'pointer' }} onClick={() => { setIsModuleOpen((prev: any) => !prev); }} />
                      {val.modules?.map((module: any) => (
                        <Grid item key={module} className={classes['roleBox']}>{modules?.find((m: { id: any; }) => m.id === module)?.name}
                          {module !== 1 && (
                            <CloseIcon sx={{ fontSize: 18, marginLeft: '3px', cursor: 'pointer' }}
                              onClick={() => {
                                val.modules = val.modules.filter((mod: any) => mod !== module)
                                forceUpdate()
                              }} />
                          )}
                        </Grid>
                      ))}

                    </Grid>

                  </HtmlTooltip >
                </Grid>
              </ClickAwayListener>

            </Grid>

          </Grid>

        ))
      }

      <Grid item className='iris-100 bold' marginTop={2}
        onClick={() => {
          addUserObject()
        }}
      >
        <HtmlTooltip placement='bottom-start'
          title=
          {
            <Grid item padding={1}>

              {listError ? <Message id="text.user-missing-field" className="Field-input labels font-weight-400" /> : <Message id="text.click-to-add-user" className="Field-input labels font-weight-400" />}

            </Grid>
          }>
          <span style={{ cursor: 'pointer' }}><Message id="ta.add.users" className="Field-input labels labels-extra-bold" /></span>
        </HtmlTooltip>
      </Grid>
      <Grid item container direction='row' justifyContent='flex-end' marginTop={3} marginBottom={2} columnGap={1}>
        <Grid item xs={2.5} >
          <button className='greyButton'
            onClick={() => navigate('/users')}
          ><Message id="button.cancel" className="Field-input labelsLinks labels-extra-bold" /></button>
        </Grid>
        <Grid item xs={2.5}>
          <button
            className={callStatus === 'Pass' ? 'greenButton' : callStatus === 'Fail' ? 'redButton' : 'blueButton'}
            disabled={loading || (activeTab === 'Tms' &&
              ((localStorage.getItem('userType') === '1' && !addedTms.every((user: any) => user.email.trim() !== "" && user.roles.length > 0 && user.accessModules.length > 0))
                || (localStorage.getItem('userType') === '2' && !addedTms.every((user: any) => user.email.trim() !== "" && user.roles.length > 0 && user.modules.length > 0))
              ))
              || (activeTab === 'Clients' && !addedClients.every((user: { email: string; name: string; roles: string | any[]; }) =>
                user.email.trim() !== "" && user.name.trim() !== "" && user.roles.length > 0))}

            onClick={() => {
              console.log('added clients', addedTms)
              setActiveSection('Review')
            }
            }
          >
            {loading ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
              : callStatus === 'Pass' ? <span><CheckCircleOutlinedIcon style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                : callStatus === 'Fail' ? "Failed"
                  : <Message id="button.next" className="Field-input labelsLinks labels-extra-bold" />}
          </button>
          <Grid item textAlign='center' className='errorText' paddingTop={1}>{errorMsg}</Grid>
        </Grid>
      </Grid>

      <ConfigureAccess
        openConfigureAccessDialog={openConfigureAccessDialog}
        setOpenConfigureAccessDialog={setOpenConfigureAccessDialog}
        user={user}
        setUser={setUser}
        modules={modules}
        taxAdvisorClients={taxAdvisorClients}
        setTaxAdvisorClients={setTaxAdvisorClients}
        type='Add TM'
        userDataLoading={false}
      />


    </Grid >
  )
}

export default AddUser