import React, { useState, useEffect, useRef } from 'react'
import { CircularProgress, Grid } from '@mui/material';
import Message from '../../../sharedComponents/ui/Message';
import classes from "../../../assets/styles/Task.module.css";
import downloadIcon from "../../../assets/images/icons/download.png";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AttachmentIcon from "../../../assets/images/icons/attachmentIcon.png";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import deleteIcon from "../../../assets/images/icons/delete.png";
import { useAppSelector } from '../../../app/hooks';
import { getActionCallFrom, getCallStatus, getErrorMsg } from '../../../app/slices/apiCallSlice';
import moment from "moment";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { borderRadius, fontFamily } from '@mui/system';
import { useIntl } from 'react-intl';
import * as StatusesConsts from "../../../status-consts";
import datevIcon from "../../../assets/images/icons/datev.png";
import datevCloseIcon from "../../../assets/images/icons/DatevCloseMark.png";
import datevCheckIcon from "../../../assets/images/icons/DatevCheckMark.png";

export interface propsInterface {
    handleFileChange: any;
    files: any,
    fileLoading: any;
    taskDetails: any;
    deleteTaskDocumentCall: any;
    uploadTaskDocumentCall: any;
    moveAttachmentsToDatevCall: any;
    movingToDatevLoading: any;
    docError:any;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        boxShadow: '0px 2px 4px 0px #0000001F',
        backgroundColor: '#FEFEFF',
        padding: '10px',
        borderRadius: '10px'
    },
}));

const ArrowTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#5D5FEF',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#5D5FEF',
        borderRadius: '8px',
        padding: '7px',
        fontFamily: 'DM Sans'
    },
}));

const Attachments = (props: propsInterface) => {

    const { handleFileChange, files, fileLoading, taskDetails, deleteTaskDocumentCall, uploadTaskDocumentCall, moveAttachmentsToDatevCall, movingToDatevLoading, docError } = props;

    // stored states
    const errorMsg = useAppSelector(getErrorMsg);
    const callStatus = useAppSelector(getCallStatus);
    const actionCallFrom = useAppSelector(getActionCallFrom);
    const intl = useIntl();
    const docs = [
        {
            "id": "1da04a29-7901-486a-bf11-277707f4369e",
            "title": "New Comment",
            "description": null,
            "type": 1,
            "commentId": "17574e3a-9823-4c9f-a40e-4367beec1986",
            "documents": ["2ad67e6f-9b89-42fc-b0f2-98bf20d7ec83"]
        }]

    const [selectedDocs, setSelectedDocs] = useState<any>([]);
    const [transferDatevHover, setTransferDatevHover] = useState(false);
    const [docIndex, setDocIndex] = useState(-1);


    const downloadFile = (uri: string, filename: string) => {
        const link = document.createElement('a');
        link.href = uri;
        link.download = filename;

        // Append to the body to ensure it's in the document
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
    };

    // const gridRef = useRef<HTMLDivElement | null>(null); useEffect(() => {

    //     if (taskDetails?.notifications?.length > 0  && taskDetails.notification?.find((n:any) => n.type === 2) && gridRef.current) {
    //         gridRef.current.scrollTo({
    //             top: gridRef.current.scrollHeight,
    //             behavior: "smooth", // Optional: "auto" for immediate scrolling
    //         });
    //     }
    // }, [taskDetails]);

    const gridRef = useRef<HTMLDivElement | null>(null); useEffect(() => {

        if (taskDetails?.notifications?.length > 0 && taskDetails?.notifications?.find((n:any)=> n.type === 2) && gridRef.current) {
            gridRef.current.scrollTo({
                top: gridRef.current.scrollHeight,
                behavior: "smooth", // Optional: "auto" for immediate scrolling
            });
        }
    }, [taskDetails]);



    return (

        <Grid container rowGap={1}>
            <Grid item container direction='row' justifyContent='space-between'>
                <Grid item container xs={6} className='black labels-extra-bold font-14' alignItems='center' columnGap={0.5}>
                    <AttachFileIcon htmlColor='#93919A' style={{ height: '20px', width: '20px' }} />
                    {/* <img src={AttachmentIcon} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' , height:'16.67px', width:'12.92px'}}/> */}
                    <Message id="documents" className="Field-input font-weight-700 font-14" />
                    <span className='nbCircle'>{files?.length}</span>
                </Grid>

                <Grid container xs={6} justifyContent='flex-end' columnGap={2}>
                    {/* {selectedDocs.length > 0 ?
                    <Grid item container direction='row' bgcolor={movingToDatevLoading ? "#CCF0EB" : ""}
                        style={{ border: "solid 1px #E6E6E8", borderRadius: '8px', cursor: 'pointer' }}
                        width={transferDatevHover ? '200px' : '60px'}
                        justifyContent='center'
                        alignItems='center'
                        padding={0.3}
                        columnGap={1}
                        onMouseOver={() => {
                            setTransferDatevHover(true);
                        }}
                        onMouseLeave={() => {
                            if (!movingToDatevLoading) {
                                setTransferDatevHover(false);
                            }
                        }
                        }
                        onClick={() => moveAttachmentsToDatevCall(selectedDocs)}
                    >
                        <Grid item container direction='row'
                            width={transferDatevHover ? '160px' : '15px'}
                            alignItems='center'
                            padding={0.2}
                            columnGap={0.5}
                            justifyContent='flex-end'>
                            {transferDatevHover ?
                                <><span className="labels neutral-2 font-14" style={{ textAlign: 'center' }}>
                                    {movingToDatevLoading ? "Aproving for Datev ..." : "Approve for DATEV"}</span>
                                    <img src={datevCheckIcon} width='12px' height='17px' /></>
                                :
                                <img src={datevCloseIcon} width='12px' height='17px' alt='icon' />
                            }
                        </Grid>
                        <Grid item container alignItems='center' width='20px'>
                            <img src={datevIcon} width='17px' height='17px' alt='icon' />
                        </Grid>
                    </Grid>
                    :
                    <></>
                } */}
                    {errorMsg ?
                        <Grid item textAlign='center' className='errorText' paddingTop={1}>{errorMsg}</Grid>
                        :
                        <></>
                    }
                        {docError ?
                         <Grid item textAlign='center' className='errorText' paddingTop={1}>{docError}</Grid>
                         :
                         <></>
                    }

                    {fileLoading ? <span style={{ color: fileLoading === 'Upload' ? '#1280BE' : '#EF4737', display: 'flex', alignItems: 'center', fontSize: '16px' }}> &nbsp;{fileLoading === 'Upload' ? "Uploading files" : "Deleting file"} &nbsp; &nbsp;<CircularProgress style={{ color: fileLoading === 'Upload' ? '#1280BE' : '#EF4737', width: '18px', height: '18px' }} /></span> : ""}
                    <ArrowTooltip title={intl.formatMessage({ id: 'document.you-can-upload' })} placement='top' >
                        <Grid item width='155px' container justifyContent='flex-end' >
                            <input
                                type="file"
                                onChange={handleFileChange}
                                disabled={taskDetails.status === StatusesConsts.CLOSED_STATUS_ID}
                                multiple
                                style={{ display: 'none' }} // Hide the default input
                                id="file-upload" // Use an ID for the label
                            />
                            <label htmlFor="file-upload" className='blueButton' style={{ cursor: taskDetails.status !== StatusesConsts.CLOSED_STATUS_ID ? 'pointer' : 'unset', minWidth:'170px' }}>
                                {/* <Message id="task.add-attachments" className="Field-input font-weight-700 font-14" /> */}
                                <Message id="document.add-documents" className="Field-input font-weight-700 font-14" />

                            </label>
                        </Grid>
                    </ArrowTooltip>
                </Grid>
            </Grid>
            <Grid item container>
                <Grid item container direction='row' className='labels neutral-3' columnSpacing={2}>
                    <Grid item xs={4.5}><Message id="document.name" className='labels neutral-3' /></Grid>
                    <Grid item xs={1}><Message id="document.size" className='labels neutral-3' /></Grid>
                    <Grid item xs={3.5}><Message id="document.date-added" className='labels neutral-3' /></Grid>
                    <Grid item xs={3} ></Grid>
                </Grid>
                <Grid item className='grey-line-full-width'></Grid>
            </Grid>
            <Grid container rowGap={0.5} marginTop={1} style={{ overflowY: 'auto', maxHeight: '180px', minHeight: '50px' }} ref={gridRef}>
                {files?.map((i: any, index: any) => (
                    <Grid item container direction="row" key={i.id} columnGap={0.2} 
                    // className={i.id ? 'black labels' : 'black bold labels'} 
                    className='black labels'
                    alignItems='center'
                        // fontWeight={taskDetails.notifications?.map((n: any) => n.documents?.includes(i.id)).some(Boolean) ? 'bold' : 'normal'}
                        style={{ border: (taskDetails?.notifications?.length > 0 && ( index >= (files?.length - (taskDetails?.notifications?.filter((n: any) => n.type === 2)?.length)))) ? '1px solid #5D5FEF' : '', borderRadius: '8px',
                            backgroundColor:(taskDetails?.notifications?.length > 0 && ( index >= (files?.length - (taskDetails?.notifications?.filter((n: any) => n.type === 2)?.length)))) ? '#E5F0FC' : ''
                         }}
                        padding={0.2}                  
                    //style={{ cursor: !i.isDatev ? 'pointer' : 'unset', backgroundColor: selectedDocs.find((d: any) => d.id === i.id) ? '#e6e6e8' : '', borderRadius: '5px', padding: '1px' }}
                    // onClick={() => {
                    //     if (!i.isDatev) {
                    //         if (selectedDocs.find((d: any) => d.id === i.id)) setSelectedDocs(selectedDocs.filter((d: any) => d.id !== i.id))
                    //         else setSelectedDocs([...selectedDocs, i])

                    //     }


                    // }}
                    >
                        <Grid item container alignItems='center' xs={4.5} columnGap={0.4}><InsertDriveFileIcon fontSize='small' htmlColor='#93919A' />{i.name}</Grid>
                        <Grid item xs={1.3}>{i.size / 1000} KB</Grid>
                        <Grid item xs={2}> {i.creationDate ? moment(i.creationDate).format('DD.MM.YYYY hA') : moment().format('DD.MM.YYYY hA')}</Grid>
                        {/* {!i.isDatev ? */}
                        <Grid item container xs={3} direction='row' justifyContent='flex-end' >
                            <Grid container bgcolor={(movingToDatevLoading && index === docIndex && !i.isDatev) || i.isDatev ? "#CCF0EB" : ""}
                                style={{ border: "solid 1px #93919A", borderRadius: '8px', cursor: !i.isDatev && !movingToDatevLoading && localStorage.getItem('userType') === '1' ? 'pointer' : 'unset' }}
                                width={transferDatevHover && index === docIndex && !i.isDatev ? '200px' : '60px'}
                                justifyContent='center'
                                alignItems='center'
                                padding={0.3}
                                columnGap={1}
                                onMouseOver={() => {
                                    if (!i.isDatev && !movingToDatevLoading && localStorage.getItem('userType') === '1') {
                                        setTransferDatevHover(true);
                                        setDocIndex(index)
                                    }

                                }}
                                onMouseLeave={() => {
                                    if (!movingToDatevLoading) {
                                        setTransferDatevHover(false);
                                        setDocIndex(-1)
                                    }
                                }
                                }
                                // onMouseOver={() => {
                                //     setTransferDatevHover(true);
                                // }}
                                // onMouseLeave={() => {
                                //     if (!movingToDatevLoading) {
                                //         setTransferDatevHover(false);
                                //     }
                                // }
                                // }
                                onClick={() => {
                                    if (localStorage.getItem('userType') === '1') {
                                        moveAttachmentsToDatevCall([i.id])
                                        setTimeout(() => {
                                            setTransferDatevHover(false);
                                            setDocIndex(-1)
                                        }, 1000)
                                    }
                                }
                                }
                            >
                                <Grid item container direction='row'
                                    width={transferDatevHover && index === docIndex ? '160px' : '20px'}
                                    alignItems='center'
                                    padding={0.2}
                                    columnGap={0.7}
                                    justifyContent='flex-end'>
                                    {transferDatevHover && index === docIndex && !i.isDatev ?
                                        <><span className="labels neutral-2 font-14" style={{ textAlign: 'center' }}>
                                            {/* {movingToDatevLoading && movingToDatevLoading? "Aproving for Datev ..." : "Approve for DATEV"} */}
                                            Approve for DATEV
                                        </span>
                                            <img src={datevCheckIcon} width='12px' height='17px' alt='icon' />

                                        </>
                                        :
                                        i.isDatev ?
                                            <img src={datevCheckIcon} width='12px' height='17px' alt='icon' />
                                            :

                                            <img src={datevCloseIcon} width='12px' height='17px' alt='icon' />
                                    }
                                </Grid>
                                <Grid item container alignItems='center' width='20px'>
                                    <img src={datevIcon} width='17px' height='17px' alt='icon' />
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* :
                            <Grid item container xs={3}></Grid>
                        } */}
                        <Grid item container direction='row' xs={1} alignItems='center' justifyContent='flex-end' columnGap={1}>
                            <Grid item
                                onClick={() => {
                                    if(taskDetails.status !== StatusesConsts.CLOSED_STATUS_ID)
                                    downloadFile(i.uri, i.name);
                                }}
                            > <img alt='icon' src={downloadIcon} width={17} height={17} style={{ cursor: taskDetails.status === StatusesConsts.CLOSED_STATUS_ID?'unset':'pointer' }} /> </Grid>
                            <Grid item>
                                <HtmlTooltip
                                disableHoverListener={taskDetails.status === StatusesConsts.CLOSED_STATUS_ID || i.isDatev}
                                    title={
                                        <React.Fragment>
                                            <Grid item container direction='column' rowGap={1} justifyContent='center' alignItems='center' >
                                                <Grid item container direction='row' alignItems='center' columnGap={0.5} style={{ cursor: taskDetails.status !== StatusesConsts.CLOSED_STATUS_ID && !i.isDatev ? 'pointer' : 'unset' }}
                                                    onClick={() => {
                                                        if (taskDetails.status !== StatusesConsts.CLOSED_STATUS_ID && !i.isDatev)
                                                            deleteTaskDocumentCall(i.id, i);
                                                    }}>
                                                    <Grid item>
                                                        <img src={deleteIcon} width='20px' height='20px' />

                                                        {/* <DeleteOutlineIcon htmlColor='#EF4737' style={{ width: "19px", height: "19px", marginTop: '3px' }} /> */}
                                                    </Grid>
                                                    <Grid item className='red labels bold'><Message id="ta.delete" className='red labels bold' /></Grid>
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    }
                                >
                                    <MoreVertIcon htmlColor='#93919A' style={{ cursor: taskDetails.status === StatusesConsts.CLOSED_STATUS_ID || i.isDatev?'unset':'pointer' }} />
                                </HtmlTooltip>
                            </Grid>
                        </Grid>

                    </Grid>
                ))}
            </Grid>
            {/* <Grid item container justifyContent='flex-end' marginTop={1}>
                <button
                    className={actionCallFrom === 'updateDocuments'? callStatus === 'Pass' ? 'greenButton' : callStatus === 'Fail' ? 'redButton' : 'blueButton': 'blueButton'}
                    style={{ width: '200px' }}
                    onClick={() => {
                        uploadTaskDocumentCall();
                    }}
                    disabled={fileLoading || files?.filter((i: any) => !i.hasOwnProperty('id'))?.length === 0 || taskDetails.status === StatusesConsts.CLOSED_STATUS_ID}>
                    {fileLoading === 'Upload' ? <CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} />
                        : callStatus === 'Pass' && actionCallFrom === 'updateDocuments' ? <CheckCircleOutlinedIcon fontSize='small' />
                            : callStatus === 'Fail' && actionCallFrom === 'updateDocuments'? "Failed"
                                : intl.formatMessage({ id: 'document.update-documents' })}
                </button>
            </Grid> */}
        </Grid >
    )

}
export default Attachments